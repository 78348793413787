<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="selectedDate"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="selectedDate"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        :hint="errorMessage"
        :persistent-hint="hasError"
      />
    </template>
    <v-date-picker
      v-model="selectedDate"
      scrollable
      :locale="$i18n.locale"
    >
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        {{ $t('buttons.close') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.dialog.save(selectedDate)"
      >
        {{ $t('buttons.ok') }}
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>

import $input from "./input-base.mixin";

export default {
  name: "InputDate",
  mixins: [$input],
  props: {
    initialDate: {
      type: String,
      optional: true
    },
    placeholder: {
      type: String,
      default: "Date"
    },
    label: {
      type: String,
      optional: true,
      default: null
    }
  },
  data() {
    return {
      selectedDate: null,
      modal: false
    };
  },

  beforeMount() {
    this.selectedDate = this.initialDate;
  },

  watch: {
    selectedDate(to) {
      this.$emit('input', to);
    }
  },

  methods: {
    resetForm() {
      this.selectedDate = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.inputDate-container {
  display: flex;
  align-items: center;

  .input-icon {
    font-size: 1.2rem;
    /*margin-right: 5px;*/
    /*margin-top: 3px;*/
    /*position: absolute;*/
    margin-right: 0.5rem;
    //margin-bottom: 2px;
    bottom: 0;
    height: 100%;
  }
}
</style>
