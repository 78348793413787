<template>
  <div class="content">
    <nav role="navigation">
      <side-bar-menu />
      <template v-if="updatePageTitle">
        <h2 class="pageTitle">{{ updatePageTitle }}</h2>
      </template>
    </nav>
  </div>
</template>

<script>
import SideBarMenu from "./SideBarMenu";

export default {
  name: "MainTopMenu",

  computed: {
    updatePageTitle() {
      return this.$t(`pagesName.` + this.$route.name);
    }
  },

  components: {
    SideBarMenu
  }
};
</script>

<style scoped lang="scss">
/*.content {*/
/*    border: 1px solid #36383F;*/
/*    border-radius: 20px;*/
/*    width: 94%;*/
/*    height: 91%;*/
/*    background-color: #F5F6FA;*/
/*    overflow: hidden;*/
/*}*/

nav {
  height: 80px;
  /*height: 100px;*/
  width: 100vw;
  position: fixed;
  /*background-color: #15396a;*/
  /*background-image: linear-gradient(180deg, #15396a, transparent);*/
  top: 0;
  left: 0;
  z-index: 200;
}

.pageTitle {
  position: absolute;
  top: calc(2rem + (var(--safe-area-inset-top) + var(--notch-inset-top)) / 2);
  left: 50%;
  transform: translateX(-50%);

  font-weight: 300;
  font-size: 0.9rem;
  text-align: center;
  text-transform: uppercase;

  width: calc(100vw - 9rem);
}
</style>
