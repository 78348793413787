<template>
  <div id="loginLayout">

    <div class="top-degrade" />

    <div class="logo-container" @click="$router.push({ name: 'Login' })" style="user-select: none; cursor: pointer">
      <h1 class="logo logo__text">Perform Away</h1>
      <img :src="require('../assets/images/ins_app_icon.svg')" class="logo logo__img" alt="INS Perform Away icon">
    </div>

    <div class="back-login" v-if="$route.name !== 'Login'">
      <a @click="$router.push({ name: 'Login' })">
        <font-awesome-icon icon="arrow-left"/>&nbsp;
        <template v-if="!user">{{ $t('back-to-login')}}</template>
        <template v-else>{{ $t('back-to-travel') }}</template>
      </a>
    </div>

    <div class="main-content">
      <slot/>
    </div>

    <div class="credits-footer" style="user-select: none">
      <small>{{ $t('poweredBy') }}</small>
      <img alt="INS logo" class="ins-logo" :src="require('../assets/images/ins_white.png')">
    </div>

    <div class="policies">
      <a @click="$router.push({ name: 'TermsAndConditions' })">{{ $t('terms-and-conditions') }}</a>
      -
      <a @click="$router.push({ name: 'PrivacyPolicy' })">{{ $t('privacy-policy') }}</a>
      <span class="hidden-md-and-down"> - </span>
      <span class="hidden-lg-and-up"><br /></span>
      <a @click="$router.push({ name: 'DeleteAccount' })">{{ $t('delete-user') }}</a>
    </div>

  </div>
</template>

<script>


import { mapGetters } from "vuex";

export default {
  name: "login-layout",
  computed: {
    ...mapGetters("user", {
      user: "currentUser"
    })
  },
  i18n: {
    messages: {
      fr: {
        "poweredBy": "Propulsé par",
        "terms-and-conditions": "Termes et conditions",
        "privacy-policy": "Politique de confidentialité",
        "delete-user": "Supprimer mes informations",
        "back-to-login": "Retourner à la page de connexion",
        "back-to-travel": "Retourner aux plans"
      },
      en: {
        "poweredBy": "Powered by",
        "terms-and-conditions": "Terms and Conditions",
        "privacy-policy": "Privacy Policy",
        "delete-user": "Delete My Information",
        "back-to-login": "Back to the login page",
        "back-to-travel": "Back to plans"
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#loginLayout {
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  justify-content: space-between;
  flex: 1;
  max-width: 95vw;
  padding-bottom: var(--safe-area-inset-bottom);

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    /*min-height: 33vh;*/
    max-height: 33vh;
    min-height: 20vh;
    flex-grow: 1;

    @media screen and (max-width: 350px) {
      min-height: 15vh;
    }

    .logo {
      font-weight: 500;
      font-size: 23px;
      letter-spacing: 1.2rem;
      height: 3rem;
      display: flex;
      align-items: center;
      text-transform: uppercase;

      &__text {
        text-align: center;
        margin-left: 24px;

        @media screen and (max-width: 373px) {
          font-size: 20px;
          padding-left: 20px;
          padding-top: 20px;
        }
      }

      &__img {
        margin-right: 2rem;
      }
    }
  }

  .main-content {
    flex-grow: 2;
    width: 100%;
  }

  .credits-footer {
    padding: 0.75rem;
    display: flex;
    align-items: center;

    .ins-logo {
      width: 4rem;
      margin: 0 0.5rem;
    }
  }


}

.policies {
  text-align: center;
  margin-bottom: 10px;
  margin-left: 5vw;
  margin-right: 5vw;

  a {
    font-family: "Roboto", sans-serif;
    color: white;
    text-decoration: none;
    font-size: 12px;
    line-break: normal;

    &:hover {
      text-decoration: underline;
    }
  }
}

.back-login {
  text-align: center;
  margin-bottom: 30px;

  a {
    color: white;

    &:hover {
      font-weight: 800;
    }
  }
}
</style>
