<template>
  <div>
    <swipe-list
      v-if="!!plans && plans.length !== 0"
      ref="list"
      class="travelCard"
      :disabled="!enabled"
      :items="plans"
      item-key="id"
      @swipeout:click="itemClick"
    >
      <template v-slot="{ item }">
        <!-- item is the corresponding object from the array -->
        <!-- index is clearly the index -->
        <!-- revealLeft is method which toggles the left side -->
        <!-- revealRight is method which toggles the right side -->
        <!-- close is method which closes an opened side -->
        <div class="travelCard-content">
          <timeline-future-element :plan="item" />
        </div>
      </template>

      <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
      <!-- remove if you dont wanna have right swipe side  -->
      <template v-slot:right="{ item }">
        <div class="swipeout-action editAction" @click="editTravel(item)">
          <font-awesome-icon :icon="['fa', 'pencil-alt']" />
        </div>
        <div
          class="swipeout-action deleteAction"
          title="remove"
          @click="remove(item)"
        >
          <font-awesome-icon :icon="['fa', 'trash']" />
        </div>
      </template>
    </swipe-list>
    <template v-if="plans.length === 0">
      <div>
        <p>{{ $t("no_travel_plans") }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import "vue-swipe-actions/dist/vue-swipe-actions.css";

import { SwipeList } from "vue-swipe-actions";
import TimelineFutureElement from "./timeline/TimelineFutureElement";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TabContentFuture",
  components: {
    SwipeList,
    TimelineFutureElement
  },
  data() {
    return {
      enabled: true
    };
  },

  computed: {
    ...mapGetters({
      plans: "plans/allPlans"
    })
  },

  methods: {
    ...mapActions({
      deletePlan: "plans/deletePlan"
    }),
    revealFirstRight() {
      this.$refs.list.revealRight(0);
    },
    revealFirstLeft() {
      this.$refs.list.revealLeft(0);
    },
    closeFirst() {
      this.$refs.list.closeActions(0);
    },
    closeAll() {
      this.$refs.list.closeActions();
    },
    close(e) {
      this.$refs.list.closeActions(e.id);
    },

    editTravel(item) {
      this.$router.push({ name: "TravelEdit", params: { id: item.id } });
    },
    async remove(item) {
      // Ask confirmation
      let modalResult = await this.$swal({
        text: this.$t("delete.message"),
        icon: "warning",
        showDenyButton: true,
        denyButtonText: this.$t("buttons.cancel"),
        showConfirmButton: true,
        confirmButtonText: this.$t("buttons.delete")
      });
      if (modalResult.isConfirmed) {
        try {
          this.loading = this.$loading.show({
            loader: "spinner",
            color: "white"
          });
          await this.deletePlan(item.id);
        }
        catch (e) {
          await this.$swal({
            text: this.$t("delete.failed"),
            icon: "error",
            showDenyButton: true,
            denyButtonText: this.$t("buttons.cancel"),
            showConfirmButton: true,
            confirmButtonText: this.$t("buttons.close")
          });
        }
        finally {
          if (this.loading) {
            this.loading.hide();
            delete this.loading;
          }
        }
      }
    },
    itemClick(item) {
      this.$router.push({ name: "TravelPlan", params: { travel: item } });
      if (this.$refs.list.isRevealed(item.id)) {
        this.$refs.list.closeActions(item.id);
      }
      else {
        this.$refs.list.revealRight(item.id);
      }
    }
  },

  watch: {
    async plans(to) {
      if (to.length === 0) {
        await this.$router.push({ name: "TravelCreate" });
      }
    }
  },

  i18n: {
    messages: {
      fr: {
        no_travel_plans: "Aucun plan en cours ou futur. Veuillez créer un plan.",
        delete: {
          message: "Êtes-vous certain(e) de vouloir supprimer ce plan ?",
          failed: "La suppression de ce plan a échoué. Veuillez réessayer plus tard ou contacter le support technique."
        }
      },
      en: {
        no_travel_plans: "No travel plan to come. Please create a new plan.",
        delete: {
          message: "Are you sure you want to delete this plan?",
          failed: "We failed to delete this travel plan. Please try again or contact support."
        }
      }
    }
  }
};
</script>

<style lang="scss">
.travelCard {
  margin-right: -1rem;
  margin-left: -1rem;
}

.swipeout .swipeout-content {
  width: 100%;
  position: relative;
  z-index: 10;
}

.travelCard-content {
  padding: 0 1rem;
  z-index: 10;
  box-shadow: -8px 0px 15px 8px #04152d;
}

.swipeout-list-item:nth-of-type(even) .swipeout-content .travelCard-content {
  background-color: #a2a1a117;
  box-shadow: -8px 0px 15px 8px rgba(4, 20, 56, 0.71);
  mix-blend-mode: screen;
}

.swipeout .swipeout-right {
  right: 0;
  transform: translateX(100%);
}

.swipeout-action {
  &.editAction,
  &.deleteAction,
  &.closeAction {
    position: relative;
    padding: 1rem 0.75rem;
    min-width: 4rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    i {
      margin-bottom: 0.3rem;
    }
  }

  &.closeAction {
    min-width: fit-content;
  }

  &.editAction {
    /* background-color: $color-dark-gray; */
    background-color: #1e75c9;
    /*color: #1e75c9;*/
  }

  &.deleteAction {
    /* background-color: $color-dark-gray; */
    background-color: #ad1940;
    /* color: #ad1940; */
  }
}
</style>
