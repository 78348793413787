<template>
  <div>
    <travel-preview v-if="plans.length > 0" :plan-preview="plans[0]" />
    <travel-preview v-else />

    <!--    Tab Section-->
    <section>
      <tabs-navigation :current-tab="'future'" />

      <div class="tabs__content">
        <tab-content-future :user-plans="plans" />
      </div>
    </section>
  </div>
</template>

<script>
import TravelPreview from "../components/travelPlans/TravelPreview";
import TabsNavigation from "../components/travelPlans/TabsNavigation";
import TabContentFuture from "../components/travelPlans/TabContentFuture";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TravelFuture",
  components: {
    TravelPreview,
    TabsNavigation,
    TabContentFuture,
  },

  computed: {
    ...mapGetters({
      plans: "plans/allPlans",
    }),
  },

  async beforeMount() {
    if (window.navigator.onLine) {
      try {
        await this.fetchPlans();
      } catch (e) {
        if (e.message === "not-logged-in") {
          this.logout();
          window.location.href = "/";
          window.localStorage.removeItem("vuex");
        }
      }
    }
    if (this.plans.length === 0) {
      await this.$router.push({ name: "TravelCreate" });
    }
  },

  methods: {
    ...mapActions({
      fetchPlans: "plans/fetchPlans",
    }),
  },
};
</script>

<style lang="scss"></style>
