<template>
  <div id="amesSurvey">
    <section v-if="!user.ames_answers || user.ames_answers.total === 0">
      <alert-banner>
        {{ $t("incomplete-profile.text") }}
      </alert-banner>
    </section>

    <h4 class="ames-instructions mb-1">
      {{ $t("amesInstructions") }}
    </h4>

    <form @submit.prevent="noop">
      <fieldset
        v-for="(item, questionIndex) in questions"
        :key="item.model"
        class="mb-1"
      >
        <p class="ames-question" :for="item.model">
          <span class="number">{{ questionIndex + 1 }}- </span>
          {{ item.question }}
        </p>
        <div v-for="(choice, index) in item.choices" :key="index">
          <input
            type="radio"
            v-model="ames_answers[item.model]"
            :value="choice[0]"
            :name="item.model + 'radio-group'"
            :id="item.model + index.toString()"
            class="radio"
          />
          <label :for="item.model + index.toString()" class="ames-choice">
            {{ choice[1] }}
          </label>
        </div>
      </fieldset>
      <div class="buttons-container">
        <button
          class="button__primary -filled button-confirm"
          @click="validateBeforeSubmit"
          style="width: 100%"
        >
          {{ $t("buttons.save") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import AlertBanner from "../components/AlertBanner";

export default {
  name: "ames-survey",
  components: {
    AlertBanner,
  },
  data() {
    return {
      backToHome: false,
      beforeScore: null,
      questions: [
        {
          model: "feeling_tired",
          question: this.$t("questions.feelingTired"),
          choices: [
            [7, this.$t("choices.feeling_tired.1")],
            [6, this.$t("choices.feeling_tired.2")],
            [5, this.$t("choices.feeling_tired.3")],
            [4, this.$t("choices.feeling_tired.4")],
            [3, this.$t("choices.feeling_tired.5")],
          ],
          required: true,
        },
        {
          model: "competition_hours",
          question: this.$t("questions.competitionHours"),
          choices: [
            [8, this.$t("choices.competition_hours.1")],
            [7, this.$t("choices.competition_hours.2")],
            [6, this.$t("choices.competition_hours.3")],
            [5, this.$t("choices.competition_hours.4")],
            [4, this.$t("choices.competition_hours.5")],
            [3, this.$t("choices.competition_hours.6")],
          ],
          required: true,
        },
        {
          model: "type_of_people",
          question: this.$t("questions.typeOfPeople"),
          choices: [
            [8, this.$t("choices.type_of_people.1")],
            [6, this.$t("choices.type_of_people.2")],
            [3, this.$t("choices.type_of_people.3")],
            [1, this.$t("choices.type_of_people.4")],
          ],
          required: true,
        },
        {
          model: "training_hours",
          question: this.$t("questions.trainingHours"),
          choices: [
            [8, this.$t("choices.training_hours.1")],
            [7, this.$t("choices.training_hours.2")],
            [6, this.$t("choices.training_hours.3")],
            [5, this.$t("choices.training_hours.4")],
            [4, this.$t("choices.training_hours.5")],
            [3, this.$t("choices.training_hours.6")],
          ],
          required: true,
        },
      ],

      ames_answers: {
        feeling_tired: null,
        competition_hours: null,
        type_of_people: null,
        training_hours: null,
      },
    };
  },

  beforeMount() {
    this.ames_answers = {
      ...this.ames_answers,
      ...this.$store.getters["user/currentUser"].ames_answers,
    };
    this.beforeScore = _.clone(
      this.user.ames_answers ? this.user.ames_answers.total : 0
    );
  },

  computed: {
    ...mapGetters("user", {
      user: "currentUser",
    }),
  },

  methods: {
    ...mapActions("user", {
      updateUser: "update",
    }),

    noop() {
      // nothing
    },

    async validateBeforeSubmit() {
      let user = _.cloneDeep(this.$store.getters["user/currentUser"]);
      user.ames_answers = _.pick(this.ames_answers, [
        "feeling_tired",
        "competition_hours",
        "type_of_people",
        "training_hours",
      ]);

      // Validate content
      if (
        !user.ames_answers ||
        !user.ames_answers.feeling_tired ||
        !user.ames_answers.competition_hours ||
        !user.ames_answers.type_of_people ||
        !user.ames_answers.training_hours
      ) {
        await this.$swal({
          text: this.$t("missing.message"),
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });
        return;
      }

      // Calculate total
      user.ames_answers.total =
        user.ames_answers.feeling_tired +
        user.ames_answers.competition_hours +
        user.ames_answers.type_of_people +
        user.ames_answers.training_hours;

      this.loading = this.$loading.show({
        loader: "spinner",
        color: "white",
      });

      try {
        // Save AMES answers
        await this.updateUser(user);

        this.loading.hide();

        // Show save success message
        this.backToHome = true;

        await this.$swal({
          text:
            this.beforeScore === 0
              ? this.$t("success.message")
              : this.$t("success.message_normal"),
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });

        await this.$router.push({
          name: this.beforeScore === 0 ? "Home" : "Profile",
        });
      } catch (error) {
        this.loading.hide();

        await this.$swal({
          text: this.$t("error.message"),
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });
      }
    },
  },
  i18n: {
    messages: {
      fr: {
        success: {
          title: "Succès",
          message:
            "Vos résultats ont été enregistré. Vous pouvez maintenant créer un plan.",
          message_normal: "Vos résultats ont été enregistré.",
        },
        error: {
          title: "Erreur",
          message:
            "Une erreur est survenue. Assurez-vous d'avoir remplit au complet le formulaire. Dans le cas contraire, veuillez réessayer plus tard.",
        },
        missing: {
          title: "Erreur",
          message:
            "Vous devez remplir les 4 champs pour sauvegarder le formulaire AMES.",
        },
        "incomplete-profile": {
          text: "Vous devez completer votre profil avant de pouvoir créer un plan.",
        },
        amesInstructions:
          "Pour compléter cette échelle, lisez chaque question et considérez attentivement toutes les réponses. Ensuite, remplissez chacun des quatre éléments de cette échelle aussi précisément que possible; une seule réponse par question.",
        questions: {
          feelingTired:
            "Le soir, à quelle heure commencez-vous généralement à vous sentir fatigué et à ressentir besoin de dormir?",
          competitionHours:
            "Supposons que vous pouvez choisir vos propres heures de compétition. (il peut être utile de penser à un bloc de 3 heures où vos performances sont «au sommet») Lequel des blocs de 3 heures parmi ces choix vous conviendrait le mieux?",
          typeOfPeople:
            "On entend parfois parler de personnes «se sentant mieux le matin» ou «se sentant mieux le soir». Comment vous définiriez-vous?",
          trainingHours:
            "Supposons que vous pouvez choisir vos propres heures d'entrainement et organiser votre calendrier afin de protéger ces heures. Lequel des blocs de 3 heures parmi ces choix vous conviendrait le mieux?",
        },
        choices: {
          feeling_tired: {
            1: "A. 20h00 - 21h30",
            2: "B. 21h31 - 22h45",
            3: "C. 22h46 - 00h30",
            4: "D. 00h31 - 01h45",
            5: "E. 01h46 - 03h00",
          },
          competition_hours: {
            1: "A. 06h00 - 9h00",
            2: "B. 09h00 - 12h00",
            3: "C. 12h00 - 15h00",
            4: "D. 15h00 - 18h00",
            5: "E. 18h00 - 21h00",
            6: "F. 21h00 - 00h00",
          },
          type_of_people: {
            1: "A. Définitivement un matinal",
            2: "B. Plus matinal qu'un couche tard",
            3: "C. Plus un couche tard qu'on matinal",
            4: "D. Définitivement un couche tard",
          },
          training_hours: {
            1: "A. 06h00 - 09h00",
            2: "B. 09h00 - 12h00",
            3: "C. 12h00 - 15h00",
            4: "D. 15h00 - 18h00",
            5: "E. 18h00 - 21h00",
            6: "F. 21h00 - 00h00",
          },
        },
        button: {
          submit: "Envoyer",
        },
      },
      en: {
        amesInstructions:
          "To complete this scale, read each question and consider all of the responses carefully. Then, complete each of the four items on this scale as accurately as you can select; only one response per question.",
        questions: {
          feelingTired:
            "At what time in the evening do you usually start feeling tired and in need of sleep?",
          competitionHours:
            "Suppose that you were able to choose your own competition hours, For some athletes, it might be useful to think about the 3-hour block when there performing “at peak”. Which one of the following 3-hour blocks would be your most preferred time?",
          typeOfPeople:
            "One sometimes hears about “feeling best in the morning” or “feeling best in evening” types of people. Which type do you consider yourself?",
          trainingHours:
            "Suppose that you were able to choose your own training (practice) hours, and organize all other daily routines to protect those hours. Which one of the following 3-hour blocks would your most preferred time?",
        },
        choices: {
          feeling_tired: {
            1: "A. 20h00 - 21h30",
            2: "B. 21h31 - 22h45",
            3: "C. 22h46 - 00h30",
            4: "D. 00h31 - 01h45",
            5: "E. 01h46 - 03h00",
          },
          competition_hours: {
            1: "A. 06h00 - 9h00",
            2: "B. 09h00 - 12h00",
            3: "C. 12h00 - 15h00",
            4: "D. 15h00 - 18h00",
            5: "E. 18h00 - 21h00",
            6: "F. 21h00 - 00h00",
          },
          type_of_people: {
            1: "A. Definitely a 'morning' type",
            2: "B. More a 'morning' than an 'evening' type",
            3: "C. Move an 'evening' than a 'morning' type",
            4: "D. Definitely an 'evening' type",
          },
          training_hours: {
            1: "A. 06h00 - 09h00",
            2: "B. 09h00 - 12h00",
            3: "C. 12h00 - 15h00",
            4: "D. 15h00 - 18h00",
            5: "E. 18h00 - 21h00",
            6: "F. 21h00 - 00h00",
          },
        },
        button: {
          submit: "Send",
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
#amesSurvey {
  max-width: 500px;
  margin: 50px auto 10px;

  .ames {
    &-instructions {
      text-align: center;
      line-height: 1.3;
    }

    &-question {
      margin-bottom: 1rem;

      span.number {
        font-weight: 800;
        color: $color-white;
      }
    }

    &-choice {
      font-weight: 500;
    }
  }

  form {
    padding: 1rem 0;
    max-width: 720px;

    fieldset {
      padding: 1rem;
      border-width: 1px;
      border-color: $color-extra-light-gray;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    line-height: 2rem;
    display: inline-block;
    color: inherit;
  }

  [type="radio"]:checked + label {
    color: $color-extra-light-blue;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border: 2px solid $color-white;
    border-radius: 100%;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: $color-extra-light-blue;
    position: absolute;
    top: calc(50% - 5px);
    left: 5px;
    transform: translateY(-50%);
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
</style>
