<template>
  <v-dialog
    ref="dialog"
    v-model="modal2"
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formatTime"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        :hint="errorMessage"
        :persistent-hint="hasError"
      >
        <template v-slot:prepend v-if="iconName && iconStyle">
          <font-awesome-icon
            :icon="[iconStyle, iconName]"
            class="airport-icon"
            style="margin-bottom: 7px; margin-left: 7px; width: 38px"
          />
        </template>
      </v-text-field>
    </template>
    <v-time-picker
      v-if="modal2"
      v-model="newTimeTmp"
      full-width
      format="24hr"
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="modal2 = false"
      >
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="save(); modal2 = false"
      >
        {{ $t('common.ok') }}
      </v-btn>
    </v-time-picker>
  </v-dialog>

</template>

<script>
import $input from "./input-base.mixin";

export default {
  name: "InputTime",
  mixins: [$input],
  data() {
    return {
      newTime: null,
      newTimeTmp: null,
      modal2: null
    };
  },
  props: {
    iconName: {
      type: String,
      default: "clock"
    },
    iconStyle: {
      type: String,
      default: "fas"
    },
    label: {
      type: String,
      optional: true
    },
    value: {
      default: null
    }
  },

  beforeMount() {
    if (this.value) {
      this.newTime = this.value.substr(0, 5);
    }
  },

  computed: {
    formatTime() {
      if (!this.newTime) {
        return null;
      }
      if (this.newTime.length > 5) {
        return this.newTime.substr(0, 5);
      }
      return this.newTime;
    }
  },

  watch: {
    modal2(to) {
      if(to === true) {
        this.newTimeTmp = this.newTime;
      }
    },
    newTime(to) {
      if (!to) {
        this.$emit("input", null);
      }
      else if (to.indexOf(":00.000") >= 0) {
        this.$emit("input", to);
      }
      else {
        this.$emit("input", to + ":00.000");
      }
    }
  },

  methods: {
    resetForm() {
      this.newTime = this.value;
    },
    save() {
      this.newTime = this.newTimeTmp;
    }
  }
};
</script>

<style lang="scss">

.inputTime_container {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;

  &:last-of-type {
    margin-right: 0;
  }
}

.vtimeselector, .timeselector {
  max-width: 140px;
  border-bottom: 1px solid white;

  &__container {
    display: inline-flex;
    align-items: flex-end;
    margin-right: 1rem;
    padding: 0.4rem 0 0;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__input {
    font-size: 0.9rem;
    padding: 0.4rem 0;
  }

  &__box {
    margin-bottom: 1rem;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.32);

    &__item {
      color: $color-primary;

      &--is-selected {
        background-color: $color-extra-light-blue;
        color: $color-white;
      }
    }
  }

  &__clear {
    display: none;
  }
}

.airport {
  &-icon {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
}
</style>
