<template>
  <!--    https://codepen.io/made-on-mars/pen/qqEgXP-->
  <!--    https://codepen.io/shieldsma91/pen/zLpbLX-->
  <div
    id="menuToggle"
    v-click-outside="{
      exclude: ['outside-click-exclude'],
      handler: closeMenu,
    }"
  >
    <input class="outside-click-exclude" id="toggleInput" type="checkbox" />
    <span></span>
    <span></span>
    <span></span>
    <div id="menu">
      <div>
        <router-link
          :to="{ name: 'Profile' }"
          class="menu__item"
          tag="li"
          v-on:click.native="closeMenu"
        >
          <profile-image />
        </router-link>
      </div>
      <ul style="padding-left: 0;">
        <router-link
          :to="{ name: 'TravelPlan' }"
          class="menu__item"
          tag="li"
          v-on:click.native="closeMenu"
        >
          {{ $t("menu.travelPlans") }}
        </router-link>
        <router-link
          :to="{ name: 'TravelCreate' }"
          class="menu__item"
          tag="li"
          v-on:click.native="closeMenu"
        >
          {{ $t("menu.createPlan") }}
        </router-link>
        <router-link
          :to="{ name: 'TravelFuture' }"
          class="menu__item"
          tag="li"
          v-on:click.native="closeMenu"
        >
          {{ $t("menu.futurePlans") }}
        </router-link>
        <router-link
          :to="{ name: 'Settings' }"
          class="menu__item"
          tag="li"
          v-on:click.native="closeMenu"
        >
          {{ $t("menu.settings") }}
        </router-link>
      </ul>

      <div class="disconnect__item">
        <li class="menu__item" tag="li" @click="disconnectUser">
          {{ $t("menu.disconnect") }}
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileImage from "../profile/ProfileImage";
import { mapActions } from "vuex";
import _isUndefined from "lodash/isUndefined";
import _isNull from "lodash/isNull";

export default {
  name: "SideBarMenu",
  components: {
    ProfileImage
  },
  methods: {
    ...mapActions("user", ["logout"]),

    closeMenu() {
      const menuInput = document.getElementById("toggleInput");
      if (!_isNull(menuInput) && !_isUndefined(menuInput)) {
        menuInput.checked = false;
      }
    },

    async disconnectUser() {
      if (!window.navigator.onLine) {
        let _confirm = confirm(this.$t("logout.no-internet"));
        if (_confirm) {
          this._logout();
        }
      }
      else {
        let modalResult = await this.$swal({
          text: this.$t("logout.confirmation.message"),
          icon: "question",
          showCancelButton: true,
          cancelButtonText: this.$t("buttons.cancel"),
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close")
        });
        if (modalResult.isConfirmed) {
          this._logout();
        }
      }
    },

    /**
     * Log out the current user.
     * @private
     */
    _logout() {
      // Route home
      this.$loading.show({
        loader: "spinner",
        color: "white"
      });
      setTimeout(() => {
        this.logout();
        window.location.href = window.location.origin;
        window.localStorage.removeItem("vuex");
      }, 2000);
    }
  },
  i18n: {
    messages: {
      fr: {
        "logout": {
          "confirmation": {
            "title": "Confirmation",
            "message": "Êtes-vous certain(e) de vouloir vous déconnecter ? Si vous le faites, vous ne recevrez plus aucune notification push jusqu'à votre prochaine connexion.",
            "button": "Déconnexion"
          },
          "no-internet": "Vous êtes présentement hors-ligne. Si vous vous déconnectez, vous n'aurez plus accès à l'application jusqu'à votre prochaine connexion."
        }
      },
      en: {
        "logout": {
          "confirmation": {
            "title": "Confirmation",
            "message": "Are you sure you want to log out? If you do, you won't receive any push notifications until your next login.",
            "button": "Log out"
          },
          "no-internet": "You are currently offline. If you log out, you will no longer have access to the app until your next login."
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#menuToggle {
  display: flex;
  flex-direction: column;
  position: relative;
  top: calc(3rem + (var(--safe-area-inset-top) + var(--notch-inset-top)) / 2);
  left: 1.5rem;
  -webkit-user-select: none;
  user-select: none;
  z-index: 300;
  margin: 0;
  padding: 0;
  width: 10px;
}

#menuToggle input {
  display: flex;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: -0.5rem;
  cursor: pointer;
  opacity: 0;
  z-index: 350;
}

#menuToggle span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 6px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  transform-origin: 6px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
  background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  z-index: 300;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-1px, 1px);
  background: $color-white;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, 0px);
}

#menu {
  //position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 28vw;
  min-width: 220px;
  box-shadow: $box-shadow-regular;
  margin: -4.5rem 0 0 -1.5rem;
  padding: calc(3rem + (var(--safe-area-inset-top) + var(--notch-inset-top))) 1.5rem calc(1rem + (var(--safe-area-inset-bottom) + var(--notch-inset-bottom)) / 2);
  background-color: $color-primary;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  z-index: 200;
  min-height: 100vh;
  top: calc(0px - var(--safe-area-inset-top) - var(--notch-inset-top));
  bottom: 0;
  height: calc(99vh + var(--safe-area-inset-top) + var(--notch-inset-top));
  padding-top: 118px;
  position: inherit;
}

#menuToggle input:checked {
  #menuToggle {
    position: fixed;
  }
}

#menu ul li, li.menu__item {
  margin-bottom: 1rem;
  list-style-type: none;
  //transition-delay: 2s;
  text-decoration: none;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.08rem;

  &:hover,
  &:focus {
    cursor: pointer;
    color: darken(white, 10);
  }

  a {
    text-decoration: none;
    letter-spacing: 0.03rem;
    font-weight: 500;
  }
}

#menuToggle input:checked ~ #menu {
  transform: none;
}

.disconnect__item {
  justify-self: flex-end;
  margin-top: auto;
}
</style>
