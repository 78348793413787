<template>
  <div :class="{'timeline-element': true, 'timeline-conflict-head': isConflict === true}">
    <div class="timeline-display">
      <span :class="{ 'timeline-bullet': true, presentDay: isToday }"></span>
      <span :class="{ 'timeline-line': true, collapsed: !detailsOpen }"></span>
    </div>

    <div class="timeline-element-content">
      <div @click="toggleDetails" class="day-header">
        <h3 class="day-title colorLightBlue">
          {{ date }}
          <font-awesome-icon v-if="isConflict" :icon="['fas', 'exclamation-circle']" />
        </h3>
        <div @click="toggleDetails" class="day-toggle">
          <font-awesome-icon
            :icon="'chevron-down'"
            @click="toggleDetails"
            v-if="!detailsOpen"
          />
          <font-awesome-icon
            :icon="'chevron-up'"
            @click="toggleDetails"
            v-else
          />
        </div>
      </div>

      <div class="day-details" v-show-slide:300:ease-in-out="detailsOpen">
        <template v-for="entry in entries">
          <timeline-sub-element :element-detail="entry" :date="dayDetails.date" :key="entry.id" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TimelineSubElement from "./TImelineSubElement";
import { DateTime } from "luxon";
import { sortBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "TimelineElement",
  components: { TimelineSubElement },
  props: {
    dayDetails: {
      type: Object,
      default() {
        return {
          day: "2020-03-12"
        };
      }
    }
  },
  data() {
    return {
      detailsOpen: false,
      date: null
    };
  },
  mounted() {
    if (this.dayDetails) {
      this.date = DateTime.fromFormat(this.dayDetails.date, "yyyy-MM-dd")
        .setLocale(this.$i18n.locale)
        .toFormat("cccc, dd LLLL");
    }
    if (this.isToday) {
      this.detailsOpen = true;
    }
  },
  computed: {
    ...mapGetters("user", {
      user: "currentUser"
    }),
    entries() {
      let entries = this.dayDetails.entries.filter((e) => {
        if (["POCS", "RCS"].includes(e.symbol) && !this.user.items.compressionSocks) {
          return false;
        }
        else if (e.symbol === "POE" && !this.user.items.electrostimulation) {
          return false;
        }
        return true;
      });
      try {
        return sortBy(entries, "timestamp");
      }
      catch (e) {
        return entries;
      }
    },
    isToday() {
      return this.date === DateTime.local()
        .setLocale(this.$i18n.locale)
        .toFormat("cccc, dd LLLL");
    },
    isConflict() {
      for (const c of this.dayDetails.entries) {
        if (c.conflict === true) {
          return true;
        }
      }
      return false;
    }
  },
  methods: {
    toggleDetails() {
      this.detailsOpen = !this.detailsOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
.timeline-conflict-head {
  h3 {
    color: red !important;
  }
}

.timeline {
  &-element {
    display: flex;
    margin-left: -0.25rem;

    &-content {
      width: 100%;
      box-sizing: border-box;
      margin-left: -0.25rem;
    }

    .timeline-display {
      margin-left: 1.5rem;
      position: relative;

      .timeline-line {
        display: inline-block;
        margin-top: 1rem;
        width: 1px;
        height: 100%;
        background-color: #87a1c4;

        /*&:last-of-type.collapsed{*/
        /*    display: none;*/
        /*}*/
      }

      .timeline-bullet {
        position: absolute;
        top: 0.75rem;
        left: 50%;
        transform: translateX(-50%);
        width: 1rem;
        height: 1rem;
        border: 2px solid $color-white;
        border-radius: 50%;
        /*<!--background-color: $color-extra-light-blue;-->*/
        background-color: $color-primary;

        &.presentDay {
          background-color: $color-extra-light-blue;
        }
      }
    }

    .day {
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        margin-left: -1.25rem;
        width: calc(100% + 1.25rem);
        padding-left: 2.25rem;
        box-sizing: border-box;
        height: 3rem;
        background-color: rgba(21, 57, 106, 0.58);

        .day-toggle {
          color: $color-medium-gray;
          padding: 0 1rem;
        }
      }

      &-title {
        text-transform: uppercase;
        color: #5baeff;
        font-weight: 400;
        font-size: 0.95rem;
        padding-left: 1.25rem;
        /*width: 28%;*/
        /*text-align: right;*/
      }
    }

    .day-details {
      padding-left: 2.25rem;

      /*overflow: visible!important;*/
      /*::v-deep .subelement:first-of-type .bullet{*/
      /*    display: none;*/
      /*}*/
    }
  }
}
</style>
