<template>
  <div class="checkbox-container">

    <div class="checkbox-icon">
      <font-awesome-icon
        :icon="[prefix, icon]"
        class=""
      />
    </div>

    <label class="checkbox-title">
      {{ title }}
    </label>

    <button
      :class="{'checkbox-option':true, '-checked':isChecked}"
      type="button"
      v-on:click="update"
    />

  </div>

</template>

<script>
export default {
  name: "CheckBoxOption",

  props: {

    value: {
      type: Boolean,
      default: false
    },

    title: {
      type: String
    },

    icon: {
      type: String,
      default: "user"
    },

    // prefix is either 'far' or 'fas' depending if it's a solid or regular font awesome icon
    prefix: {
      type: String,
      default: "far"
    }
  },

  mounted() {
    this.isChecked = this.value;
  },
  data() {
    return {
      isChecked: false
    };
  },

  methods: {
    update() {
      this.isChecked = !this.isChecked;
      this.$emit("input", (this.isChecked));
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox {
  &-container {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    min-height: 2.75rem;
    /*height: 2.75rem;*/
    margin-top: 1rem;
  }

  &-icon {
    height: 100%;
    width: 2.75rem;
    padding: 0.5rem 1rem 0.5rem 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
  }

  &-option, &-title {
    //outline: 1px solid $color-white;
    border: 1px solid $color-input-gray;
    padding: 0.5rem 0.5rem;
    transition: .3s ease background-color;
    background: inherit;
    /*height: 100%;*/
    align-self: stretch;
    line-height: 1.2;
  }

  &-title {
    flex: 1;
    display: flex;
    align-items: center;
  }

  &-option {
    /*height: 100%;*/
    width: 2.75rem;
    border-left-width: 0;

    &.-checked {
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('data:image/svg+xml;utf8, <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path fill="white" d="m20.292969 5.2929688-11.292969 11.2929692-4.2929688-4.292969-1.4140624 1.414062 5.7070312 5.707031 12.707031-12.7070308z"/></svg>');
    }
  }
}

#app button.checkbox-option {
  &.-checked:focus, &:focus {
    outline: 1px solid $color-white;
  }
}
</style>
