<template>
  <div id="defaultLayout">

    <div class="top-degrade" />

    <main-top-menu />

    <div class="main-container">
      <slot />

    </div>
  </div>
</template>

<script>
import MainTopMenu from "../components/navigation/MainTopMenu";

export default {
  name: "default-layout",
  components: { MainTopMenu }
};
</script>

<style scoped lang="scss">
.main-container {
  padding: 1rem;
  max-width: 1140px;
  margin: calc((65px + var(--safe-area-inset-top) + var(--notch-inset-top)) / 2) auto calc(var(--safe-area-inset-bottom) + var(--notch-inset-bottom)) auto;
  min-width: 700px;

  @media screen and (max-width: 700px) {
    min-width: 100vw;
  }
}
</style>
