<template>
  <div class="tab__container" style="max-width: 600px; margin: auto;">
    <div>
      <!-- FROM/DEPARTURE SECTION-->
      <section>

        <input-select
          :label="$t('options.title')"
          :options="options"
          v-model="travelForm.direction"
          :value="travelForm.direction"
          :errors="$v.travelForm.direction"
          @input="travelForm.direction = $event"
          ref="direction"
        />
        <input-program
          v-model="travelForm.maxDaysBefore"
          :label="$t('when_start_program')"
          :errors="$v.travelForm.maxDaysBefore"
          ref="maxDaysBefore"
        />

        <label class="input__label" style="color: #5baeff; font-weight: bold">
          {{ $t("from") }}
        </label>

        <input-airport
          :errors="$v.travelForm.departureAirport"
          ref="departure"
          v-model="travelForm.departureAirport"
          :placeholder="$t('fromAirport')"
        />

        <div class="flex-center mt-1">
          <v-row>
            <v-col>
              <input-date
                ref="departureDate"
                class="input__date"
                :errors="$v.travelForm.departureDate"
                :initial-date="travelForm.departureDate"
                :placeholder="$t('inputDate.departure')"
                @selected-date="travelForm.departureDate = $event"
                v-model="travelForm.departureDate"
                :label="$t('inputDate.departure')"
              />
            </v-col>
            <v-col>
              <label
                class="input__error -error"
                v-if="formErrors && formErrors.length > 0"
              >
                {{ formErrors.departureDate }}
              </label>

              <input-time
                ref="departureTime"
                class="input__time"
                :errors="$v.travelForm.departureTime"
                :icon-name="'plane-departure'"
                :reference-string="'departTime'"
                v-model="travelForm.departureTime"
                :label="$t('inputTime.departure')"
              />
            </v-col>
          </v-row>
        </div>
      </section>

      <!-- TO/ARRIVAL SECTION-->
      <section class="mt-2">
        <label class="input__label" style="color: #5baeff; font-weight: bold">
          {{ $t("to") }}
        </label>

        <input-airport
          ref="arrival"
          :errors="$v.travelForm.arrivalAirport"
          v-model="travelForm.arrivalAirport"
          :placeholder="$t('toAirport')"
        />

        <div class="flex-center mt-1">
          <v-row>
            <v-col>
              <input-date
                ref="arrivalDate"
                class="input__date"
                :errors="$v.travelForm.arrivalDate"
                :initial-date="travelForm.arrivalDate"
                :placeholder="$t('inputDate.arrival')"
                @selected-date="travelForm.arrivalDate = $event"
                v-model="travelForm.arrivalDate"
                :label="$t('inputDate.arrival')"
              />
            </v-col>
            <v-col>
              <input-time
                ref="arrivalTime"
                class="input__time"
                :errors="$v.travelForm.arrivalTime"
                :icon-name="'plane-arrival'"
                :reference-string="'arrivalTime'"
                v-model="travelForm.arrivalTime"
                :label="$t('inputTime.arrival')"
              />
            </v-col>
          </v-row>
        </div>
      </section>

      <!-- TIME CONSTRAINTS SECTION -->
      <section class="mt-2">
        <label class="input__label" style="color: #5baeff; font-weight: bold">
          {{ $t("timeConstraints.title") }}
        </label>

        <v-card
          light
          class="mt-1"
          v-for="(v, index) in $v.travelForm.timeConstraint.$each.$iter"
          :key="index"
          style="padding: 10px 25px"
        >
          <font-awesome-icon
            :icon="'times-circle'"
            @click="deleteConstraint(v.$model, index)"
            class="delete-icon"
          />

          <input-date
            class="input__date"
            :errors="v.date"
            :initial-date="v.date.$model"
            @selected-date="v.date.$model = $event"
            v-model="v.date.$model"
            :label="$t('constraint_date')"
          />

          <div class="flex-center mb-1">
            <v-row>
              <v-col>
                <input-time
                  :errors="v.start"
                  :icon-name="'clock'"
                  :icon-style="'far'"
                  :label="$t('timeConstraints.startLabel')"
                  :time="v.start.$model"
                  @time-set="v.start.$model = $event"
                  class="input__time"
                  :reference-string="`startTime${index}`"
                  v-model="v.start.$model"
                />
              </v-col>
              <v-col>
                <input-time
                  :errors="v.end"
                  :icon-name="'clock'"
                  :icon-style="'far'"
                  :label="$t('timeConstraints.endLabel')"
                  :time="v.end.$model"
                  @time-set="v.end.$model = $event"
                  class="input__time"
                  :reference-string="`endTime${index}`"
                  v-model="v.end.$model"
                />
              </v-col>
            </v-row>
          </div>
          <div class="fullWidth">
            <input-select
              :errors="v.name"
              :label="$t('timeConstraints.type')"
              :placeholder="$t('timeConstraints.typePlaceholder')"
              v-model="v.name.$model"
              :value="v.name.$model"
              :options="constraintsNames"
              :full="true"
            />
          </div>
          <div class="mb-2 mt-1">
            <v-switch
              v-model="v.beforeDeparture.$model"
              :label="$t('timeConstraints.beforeDeparture')"
              hide-details
            />
          </div>
        </v-card>

        <!-- Button to add a constraint -->
        <button
          @click="addConstraint"
          class="button__primary -filledDark -lightColor fullWidth mt-1 add-button"
          type="button"
        >
          <span>{{ $t("timeConstraints.addButton") }}</span>
          <font-awesome-icon
            :icon="'plus'"
            class="add-icon"
          />
        </button>
      </section>

      <div class="buttons-container">
        <button
          @click="resetForm"
          class="button__primary -cancel"
          type="reset"
        >
          {{ $t("buttons.clear") }}
        </button>
        <button
          v-if="!existingPlan"
          class="button__primary -filled button-confirm"
          type="button"
          @click="validateBeforeSubmit"
        >
          {{ $t("buttons.save") }}
        </button>
        <button
          v-else
          class="button__primary -filled button-confirm"
          type="button"
          @click="validateBeforeSubmit"
        >
          {{ $t("buttons.modify") }}
        </button>
      </div>
      <!---->
    </div>

  </div>
</template>

<script>
import InputAirport from "../common/inputs/InputAirport";
import InputTime from "../common/inputs/InputTime";
import InputDate from "../common/inputs/InputDate";
import InputSelect from "../common/inputs/InputSelect";
import InputProgram from "../common/inputs/InputProgram";

import { mapActions, mapGetters } from "vuex";
import { required, minValue, maxValue } from "vuelidate/lib/validators";

export default {
  name: "TabContentCreate",
  data() {
    return {
      travelForm: {
        departureAirport: null,
        arrivalAirport: null,
        departureDate: null,
        departureTime: null,
        arrivalDate: null,
        arrivalTime: null,
        timeConstraint: [],
        direction: null,
        maxDaysBefore: 2
      },
      formErrors: {},
      loading: false,
      options: [
        {
          text: this.$t("options.easiest"),
          value: "easiest"
        },
        {
          text: this.$t("options.advance"),
          value: "advance"
        },
        {
          text: this.$t("options.delay"),
          value: "delay"
        }
      ],
      constraintsNames: [
        {
          text: this.$t("actions.training"),
          value: "training"
        },
        {
          text: this.$t("actions.competition"),
          value: "competition"
        },
        {
          text: this.$t("actions.meeting"),
          value: "meeting"
        }
      ]
    };
  },
  components: {
    InputProgram,
    //InputNumber,
    InputSelect,
    //InputCheckbox,
    InputAirport,
    InputTime,
    InputDate
  },

  props: {
    travel: {
      type: Object,
      optional: true
    },
    existingPlan: {
      type: Boolean,
      default: false
    }
  },

  validations: {
    travelForm: {
      departureAirport: {
        required
      },
      arrivalAirport: {
        required
      },
      departureDate: {
        required
      },
      departureTime: {
        required
      },
      arrivalDate: {
        required
      },
      arrivalTime: {
        required
      },
      direction: {
        required
      },
      maxDaysBefore: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(3)
      },
      timeConstraint: {
        $each: {
          date: {
            required
          },
          start: {
            required
          },
          end: {
            required
          },
          name: {
            required
          },
          beforeDeparture: {
            // Nothing, simply null true or false
          }
        }
      }
    }
  },

  async beforeMount() {
    if (this.travel) {
      this.travelForm = this.travel;
    }
    if (this.travelForm.direction === null) {
      if (this.user.ames_answers.total > 24) {
        this.travelForm.direction = this.options[1].value;
      }
      else {
        this.travelForm.direction = this.options[0].value;
      }
    }
    else {
      if (this.travelForm.direction === "easiest") {
        this.travelForm.direction = this.options[0].value;
      }
      else if (this.travelForm.direction === "advance") {
        this.travelForm.direction = this.options[1].value;
      }
      else if (this.travelForm.direction === "delay") {
        this.travelForm.direction = this.options[2].value;
      }
    }

    await this.fetchAirports();
  },

  computed: {
    ...mapGetters("user", {
      user: "currentUser"
    })
  },

  methods: {
    ...mapActions("plans", {
      upsertPlan: "upsertPlan"
    }),

    /**
     * Validation du formulaire et emission du data au parent
     */
    async validateBeforeSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.error("INVALID FORM", this.$v);
        return;
      }

      // Check for AMES extreme
      await this._suggestMorningPersonIfRequired();
      await this._suggestLatePersonIfRequired();

      this.loading = this.$loading.show({
        loader: "spinner",
        color: "white"
      });

      try {
        let algoResponse = await this.upsertPlan({
          plan: {
            ...this.travelForm,
            timeline: undefined,
            user: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            direction: this.travelForm.direction,
            timeConstraint: this.travelForm.timeConstraint
          }
        });
        this.loading.hide();

        // Now check if we found a better plan. If so, suggest it to the user
        if (algoResponse.conflicts && algoResponse.foundBetterPlan) {
          await this._handleConflictsWithBetterPlan(algoResponse);
        }
        else if (algoResponse.conflicts && !algoResponse.foundBetterPlan) {
          await this._handleConflictsWithoutBetterPlan(algoResponse);
        }
        else {
          await this.$router.push({ name: "TravelFuture" });
        }
      }
      catch (err) {
        this.loading.hide();

        if (err.message === "NO_JETLAG") {
          await this.$swal({
            text: this.$t("errors.no-jetlag"),
            icon: "info",
            showConfirmButton: true,
            confirmButtonText: this.$t("buttons.close")
          });
        }
        else if(["ARRIVAL_DATE_IS_INVALID", "DEPARTURE_DATE_IS_INVALID"].includes(err.message)) {
          await this.$swal({
            text: this.$t("errors.bad-dates"),
            icon: "info",
            showConfirmButton: true,
            confirmButtonText: this.$t("buttons.close")
          });
        }
        else if (err.message === "ARRIVAL_IS_BEFORE_DEPARTURE") {
          await this.$swal({
            text: this.$t("errors.arrival-is-before-departure"),
            icon: "info",
            showConfirmButton: true,
            confirmButtonText: this.$t("buttons.close")
          });
        }
        else {
          await this.$swal({
            text: this.$t("error.unknown") + err.message,
            icon: "info",
            showConfirmButton: true,
            confirmButtonText: this.$t("buttons.close")
          });
        }
      }
    },

    async _suggestMorningPersonIfRequired() {
      if (this.user.ames_answers.total >= 29 && this.travelForm.direction !== "advance") {
        let result = await this.$swal({
          icon: "question",
          text: this.$t('personTypeDetection.morning'),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showDenyButton: true,
          denyButtonText: "Non",
          confirmButtonText: "Oui",
          footer: `<p style="text-align: center; font-size: 12px; color: slategray">${ this.$t('personTypeDetection.why') }</a>`
        });
        if (result.isConfirmed) {
          this.travelForm.direction = "advance";
        }
      }
    },

    async _suggestLatePersonIfRequired() {
      if (this.user.ames_answers.total <= 12 && this.travelForm.direction !== "delay") {
        let result = await this.$swal({
          icon: "question",
          text: this.$t('personTypeDetection.late'),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showDenyButton: true,
          denyButtonText: "Non",
          confirmButtonText: "Oui",
          footer: `<p style="text-align: center; font-size: 12px; color: slategray">${ this.$t('personTypeDetection.why') }</a>`
        });
        if (result.isConfirmed) {
          this.travelForm.direction = "delay";
        }
      }
    },

    async _handleConflictsWithoutBetterPlan(algoResponse) {
      // Find all the timeline entries that are in conflict and show them. Ask the user if he prefer to update it plan or to
      // Build HTML message
      let hashes = [];
      let html = `<p>${ this.$t('conflicts.noSuggestions') }</p>
<br />
<p>${ this.$t('conflicts.listOfConflicts') } : </p>
<br />`;
      html += `<table style="width: 100%">
<thead>
<tr>
<th>${this.$t("constraint.name")}</th>
<th>${this.$t("constraint.start")}</th>
<th>${this.$t("constraint.end")}</th>
</tr>
</thead>`;
      for (const date of algoResponse.plan.timeline) {
        for (const entry of date.entries) {
          if (entry.conflict === true) {
            if (hashes.indexOf(entry.conflictWith.hash) >= 0) {
              continue;
            }
            hashes.push(entry.conflictWith.hash);
            html += `<tr>
<td style="padding: 5px">${this.$t("actions." + entry.conflictWith.name)}</td>
<td style="padding: 5px">${entry.conflictWith.start.substr(0, 16).replace("T", " ")}</td>
<td style="padding: 5px">${entry.conflictWith.end.substr(0, 16).replace("T", " ")}</td>
</tr>`;
          }
        }
      }
      html += `</table><br /><p>${ this.$t('conflicts.listOfRecommendations') }: </p><br />`;
      html += `<table style="width: 100%">
<thead>
<tr>
<th>${this.$t("recommendation.name")}</th>
<th>${this.$t("recommendation.date")}</th>
<th>${this.$t("constraint.start")}</th>
<th>${this.$t("constraint.end")}</th>
</tr>
</thead>`;
      for (const date of algoResponse.plan.timeline) {
        for (const entry of date.entries) {
          if (entry.conflict === true) {
            html += `<tr>
<td style="padding: 5px">${this.$t("recommendation." + (entry.action.toLowerCase().indexOf("sleep") >= 0 ? "sleep" : "dark"))}</td>
<td style="padding: 5px">${date.date}</td>
<td style="padding: 5px">${entry.start.substr(0, 5)}</td>
<td style="padding: 5px">${entry.end.substr(0, 5)}</td>
</tr>`;
          }
        }
      }
      html += `</table><br /><p>${ this.$t('conflicts.continueOrEdit') }</p>`;
      let modalResult = await this.$swal({
        icon: "warning",
        html,
        width: 600,
        padding: "1rem",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: this.$t("buttons.cancelPlan"),
        denyButtonText: this.$t("buttons.updatePlan"),
        confirmButtonText: this.$t("buttons.keepPlan")
      });
      if (modalResult.isConfirmed) {
        await this.upsertPlan({
          plan: {
            ...this.travelForm,
            timeline: undefined,
            user: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            direction: this.travelForm.direction,
            timeConstraint: this.travelForm.timeConstraint
          },
          confirm: true
        });
        await this.$router.push({ name: "TravelFuture" });
      }
      else if (modalResult.isDismissed) {
        await this.$router.push({ name: "TravelFuture" });
      }
    },

    async _handleConflictsWithBetterPlan(algoResponse) {
      // Find all the timeline entries that are in conflict and show them. Ask the user if he prefer to update it plan or to
      // Build HTML message
      let hashes = [];
      let html = `<p>${ this.$t('conflicts.withSuggestions') }</p>
<br />
<p>${ this.$t('conflicts.listOfConflicts') } : </p>
<br />`;
      html += `<table style="width: 100%">
<thead>
<tr>
<th>${this.$t("constraint.name")}</th>
<th>${this.$t("constraint.start")}</th>
<th>${this.$t("constraint.end")}</th>
</tr>
</thead>`;
      for (const date of algoResponse.plan.timeline) {
        for (const entry of date.entries) {
          if (entry.conflict === true) {
            if (hashes.indexOf(entry.conflictWith.hash) >= 0) {
              continue;
            }
            hashes.push(entry.conflictWith.hash);
            html += `<tr>
<td style="padding: 5px">${this.$t("actions." + entry.conflictWith.name)}</td>
<td style="padding: 5px">${entry.conflictWith.start.substr(0, 16).replace("T", " ")}</td>
<td style="padding: 5px">${entry.conflictWith.end.substr(0, 16).replace("T", " ")}</td>
</tr>`;
          }
        }
      }
      html += `</table><br /><p>${ this.$t('conflicts.listOfRecommendations') }: </p><br />`;
      html += `<table style="width: 100%">
<thead>
<tr>
<th>${this.$t("recommendation.name")}</th>
<th>${this.$t("recommendation.date")}</th>
<th>${this.$t("constraint.start")}</th>
<th>${this.$t("constraint.end")}</th>
</tr>
</thead>`;
      for (const date of algoResponse.plan.timeline) {
        for (const entry of date.entries) {
          if (entry.conflict === true) {
            html += `<tr>
<td style="padding: 5px">${this.$t("recommendation." + (entry.action.toLowerCase().indexOf("sleep") >= 0 ? "sleep" : "dark"))}</td>
<td style="padding: 5px">${date.date}</td>
<td style="padding: 5px">${entry.start.substr(0, 5)}</td>
<td style="padding: 5px">${entry.end.substr(0, 5)}</td>
</tr>`;
          }
        }
      }
      html += `</table><br /><b>${ this.$t('conflicts.actualPlan') }: </b><br />`;
      html += `<table style="width: 100%"><tr>
<td style="padding: 5px">${this.$t("recommendation.direction")}</td>
<td style="padding: 5px">${this.$t("options." + algoResponse.plan.rawResult.plan.direction)}</td>
</tr>
<tr>
<td style="padding: 5px">${this.$t("recommendation.nbOfConflicts")}</td>
<td style="padding: 5px">${algoResponse.plan.rawResult.plan.nbOfConflicts}</td>
</tr>
<tr>
<td style="padding: 5px">${this.$t("recommendation.nbOfDays")}</td>
<td style="padding: 5px">${algoResponse.plan.rawResult.plan.nbOfDays}</td>
</tr>
<tr>
<td style="padding: 5px">${this.$t("recommendation.nbOfDaysBeforeDeparture")}</td>
<td style="padding: 5px">${algoResponse.plan.rawResult.plan.days}</td>
</tr></table>`;
      html += `<br /><b>${ this.$t('conflicts.proposedPlan') }: </b><br />`;
      html += `<table style="width: 100%"><tr>
<td style="padding: 5px">${this.$t("recommendation.direction")}</td>
<td style="padding: 5px">${this.$t("options." + algoResponse.suggestedPlan.direction)}</td>
</tr>
<tr>
<td style="padding: 5px">${this.$t("recommendation.nbOfConflicts")}</td>
<td style="padding: 5px">${algoResponse.suggestedPlan.nbOfConflicts}</td>
</tr>
<tr>
<td style="padding: 5px">${this.$t("recommendation.nbOfDays")}</td>
<td style="padding: 5px">${algoResponse.suggestedPlan.nbOfDays}</td>
</tr>
<tr>
<td style="padding: 5px">${this.$t("recommendation.nbOfDaysBeforeDeparture")}</td>
<td style="padding: 5px">${algoResponse.suggestedPlan.days}</td>
</tr></table>`;
      html += `<br /><p>${ this.$t('conflicts.continueKeepOrCancel') }</p>`;
      let modalResult = await this.$swal({
        icon: "warning",
        html,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: this.$t('conflicts.cancelPlan'),
        denyButtonText: this.$t('conflicts.proposedPlan'),
        confirmButtonText: this.$t('conflicts.actualPlan')
      });
      if (modalResult.isConfirmed) {
        await this.upsertPlan({
          plan: {
            ...this.travelForm,
            timeline: undefined,
            user: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            program: algoResponse.plan.program,
            maxDaysBefore: algoResponse.plan.maxDaysBefore,
            direction: algoResponse.plan.rawResult.plan.info.direction,
            timeConstraint: this.travelForm.timeConstraint
          },
          confirm: true
        });
        await this.$router.push({ name: "TravelFuture" });
      }
      else if (modalResult.isDenied) {
        await this.upsertPlan({
          plan: {
            ...this.travelForm,
            timeline: undefined,
            user: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            program: algoResponse.suggestedPlan.program,
            maxDaysBefore: algoResponse.suggestedPlan.days,
            direction: algoResponse.suggestedPlan.direction,
            timeConstraint: this.travelForm.timeConstraint
          },
          confirm: true
        });
        await this.$router.push({ name: "TravelFuture" });
      }
    },

    addConstraint() {
      this.travelForm.timeConstraint.push({
        date: null,
        start: null,
        end: null,
        name: this.constraintsNames[0].value,
        beforeDeparture: false,
        conflict: false
      });
    },

    deleteConstraint(constraint, index) {
      this.travelForm.timeConstraint.splice(index, 1);
    },

    isConstraintNull(constraint) {
      let rules = "";

      for (const property in constraint) {
        if (constraint[property]) {
          rules = "required";
        }
      }

      return rules;
    },

    resetForm() {
      this.travelForm.departureAirport = null;
      this.travelForm.arrivalAirport = null;
      this.travelForm.departureDate = null;
      this.travelForm.departureTime = null;
      this.travelForm.arrivalDate = null;
      this.travelForm.arrivalTime = null;
      this.travelForm.timeConstraint = [];
      this.travelForm.maxDaysBefore = 3;
      this.travelForm.direction = this.options[0].value;
      this.$refs.arrival.resetForm();
      this.$refs.departure.resetForm();
      this.$refs.arrivalTime.resetForm();
      this.$refs.departureTime.resetForm();
      this.$refs.arrivalDate.resetForm();
      this.$refs.departureDate.resetForm();
      this.$refs.direction.resetForm();
      this.$refs.maxDaysBefore.resetForm();
    }
  },

  watch: {
    travelForm: {
      handler: function(newVal) {
        this.$emit("plan-modify", newVal);
      },
      deep: true
    }
  },

  i18n: {
    messages: {
      fr: {
        from: "Départ",
        fromAirport: "Entrez votre aéroport de départ",
        to: "Destination",
        toAirport: "Entrez votre aéroport de destination",
        inputDate: {
          "departure": "Date de départ",
          "arrival": "Date d'arrivée"
        },
        inputTime: {
          "departure": "Heure de départ",
          "arrival": "Heure d'arrivée"
        },
        timeConstraints: {
          "title": "Activités prévues",
          "startLabel": "Début",
          "endLabel": "Fin",
          "type": "Type d'activité",
          "typePlaceholder": "Entraînement, compétition, etc.",
          "addButton": "Ajouter des contraintes",
          "beforeDeparture": "Avant le départ"
        },
        conflicts: {
          "title": "Conflit(s) trouvé(s)",
          "message": "Nous avons détecté un/des conflits avec l'horaire proposé. Veuillez consulter le plan afin de voir les conflits et peut-être les déplacer.",
          withSuggestions: "Nous avons détecté des conflits dans votre horaire et nous avons été capable de trouver un plan qui convenait mieux à vos contraintes.",
          noSuggestions: "Nous avons détecté des conflits dans votre horaire et n'avons pu trouver un meilleur plan à vous proposer.",
          listOfConflicts: "Liste des contraintes causant un/des conflits",
          listOfRecommendations: "Liste des recommandations qui entrent en conflit avec ces contraintes",
          continueOrEdit: "Désirez-vous modifier votre plan pour ajuster vos contraintes ou confirmer la création du plan telquel ?",
          cancelPlan: "Annuler",
          actualPlan: "Plan actuel",
          proposedPlan: "Plan proposé",
          continueKeepOrCancel: "Désirez-vous utiliser le plan proposé, conserver votre plan actuel ou annuler ?"
        },
        errors: {
          "no-jetlag": "Notre programme n'a pas détecté de jet lag entre les 2 destinations. Vous pouvez donc continuer votre routine habituelle.",
          "bad-dates": "La date de départ et/ou la date d'arrivé n'est pas valide. Veuillez corriger ces informations.",
          "arrival-is-before-departure": "La date d'arrivée précède la date de départ. Veuillez corriger cette information.",
          "unknown": "Une erreur est survenue lors de la création. Veuillez vérifier vos informations ou réessayer plus tard. Si le problème persiste, veuillez contacter le support technique. <br /><br />Code d'erreur : "
        },
        options: {
          "title": "Décalage de l'heure de sommeil",
          "easiest": "Le plan le plus court (Par défaut)",
          "advance": "Plus tôt (pour les personnes matinales)",
          "delay": "Plus tard (retarder)"
        },
        recommendation: {
          "name": "Recom.",
          "date": "Date",
          "dark": "Noirceur",
          "sleep": "Dormir",
          "direction": "Direction",
          "nbOfConflicts": "Conflit(s)",
          "nbOfDays": "Nombre de jours",
          "nbOfDaysBeforeDeparture": "Jours avant décollage"
        },
        buttons: {
          "cancelPlan": "Annuler les modifications",
          "keepPlan": "Conserver le plan telquel",
          "updatePlan": "Modifier le plan"
        },
        personTypeDetection: {
          morning: "Nous avons détecté que vous êtes une personne très matinale. Désirez-vous un plan plus adapté à votre profil ?",
          late: "Nous avons détecté que vous êtes une personne très couche tard. Désirez-vous un plan plus adapté à votre profil ?",
          why: "Nous vous proposons ceci puisque vous avez rempli le questionnaire sur vos habitudes de sommeil."
        },
        when_start_program: 'Quand commencer le programme (avant)',
        constraint_date: 'Date de la contrainte'
      },
      en: {
        from: "From",
        to: "To",
        fromAirport: "Enter your departure airport",
        toAirport: "Enter your destination airport",
        inputDate: {
          "departure": "Departure date",
          "arrival": "Arrival date"
        },
        inputTime: {
          "departure": "Departure time",
          "arrival": "Arrival time"
        },
        timeConstraints: {
          "title": "Scheduled Activities",
          "startLabel": "Start",
          "endLabel": "End",
          "type": "Activity type",
          "typePlaceholder": "Activity Type (training, competition, etc.)",
          "addButton": "Add time constraints",
          "beforeDeparture": "Before departure"
        },
        conflicts: {
          "title": "Conflict(s) found",
          "message": "We have detected some conflict(s) with the proposed schedule. Please consult the map to see conflicts and possibly move them.",
          withSuggestions: "We have detected conflicts in your schedule and were able to find a plan that best suited your constraints.",
          noSuggestions: "We have detected conflicts in your schedule and could not find a better plan to offer you.",
          listOfConflicts: "List of constraints causing conflict(s)",
          listOfRecommendations: "List of recommendations that conflict with these constraints",
          continueOrEdit: "Do you want to update your plan to adjust with your constraints or confirm the creation of the plan as is?",
          cancelPlan: "Cancel",
          actualPlan: "Current plan",
          proposedPlan: "Proposed plan",
          continueKeepOrCancel: "Do you want to use the proposed plan, keep your current plan or cancel?"
        },
        errors: {
          "no-jetlag": "Our program did not detect any jet lag between the 2 destinations. You can therefore continue with your usual routine.",
          "bad-dates": "The departure date and/or the arrival date is not valid. Please correct this information.",
          "arrival-is-before-departure": "The arrival date precedes the departure date. Please correct this information.",
          "unknown": "An error occurred while creating. Please verify your information or try again later. If the problem persists, please contact technical support. <br /> <br /> Error code: "
        },
        options: {
          "title": "Offset of sleep time ",
          "easiest": "The shortest plan (default)",
          "advance": "Advance (for morning people)",
          "delay": "Delay (delay)"
        },
        recommendation: {
          "name": "Name",
          "date": "Date",
          "dark": "Dark",
          "sleep": "Sleep"
        },
        buttons: {
          cancelPlan: "Revert modifications",
          keepPlan: "Keep the plan",
          updatePlan: "Update the plan"
        },
        personTypeDetection: {
          morning: "We have detected that you are a very early morning person. Do you want a plan more suited to your profile?",
          late: "We have detected that you are a very late night person. Do you want a plan more suited to your profile?",
          why: "We offer this to you since you have completed the AMES form on your sleep habits."
        },
        when_start_program: 'When to start the program (before)',
        constraint_date: 'Constraint date'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
form {
  padding: 1rem 0;
}

.input__date {
  width: calc(50% - 0.5rem);
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.input__time {
  width: calc(50% - 0.5rem);
}

.timeConstraint {
  border-bottom: 1px solid #5baeff8f;
  position: relative;
  padding-top: 5px;
  width: 100%;

  .input__date {
    margin-top: 1rem;
    width: calc(100% - 2rem);
  }
}

.delete-icon {
  position: absolute;
  top: 0.8rem;
  right: 10px;
  color: $color-delete;
  //border: 1px solid $color-white;
  border-radius: 50%;
  //background-color: $color-white;
  font-size: 1.2rem;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin: 0 0.25rem;
  }
}

.add-icon {
  border: 1px solid $color-white;
  margin: 0 0.25rem;
  width: 1.2rem;
  height: 1.2rem;
  padding: 3px;
  border-radius: 50%;
}
</style>
