<template>
  <input-text
    :label="label || $t('inputs.email.label')"
    :name="$t('inputs.email.label')"
    :placeholder="$t('inputs.email.placeholder')"
    :type="'email'"
    :errors="errors"
    :value="value"
    :v-model="value"
    @input="$emit('input', $event)"
  />
</template>
<script>
import InputText from "./InputText";
import $input from "./input-base.mixin";
import randomId from "random-entities/id";

export default {
  name: "input-emails",
  mixins: [$input],
  components: { InputText },

  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      optional: true
    }
  },
  mounted() {
    if (this.value) {
      this.email = this.value;
    }
  },

  data() {
    return {
      id: randomId(),
      email: ""
    };
  },

  watch: {
    email: {
      handler: function() {
        this.emit();
      },
      deep: true
    }
  },

  methods: {
    focus() {
      this.$emit("focus");
      this.enableCondition();
      if (!this.$input.valid) {
        this.clearValidation();
      }
    },

    emit() {
      this.email.toLowerCase().trim();
      this.$emit("input", this.email);
    }
  }
};
</script>

<style lang="scss">
.svg-delete {
  margin-top: 1.5rem;

  &:hover {
    color: cc(alert);
  }
}

.svg-add {
  margin-top: 1.5rem;

  &:hover {
    color: cc(primary);
  }
}

.input-multiple {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__input {
    flex-grow: 2;
    padding-right: 2rem;
  }

  &__select {
    padding-right: 0.5rem;
    flex-grow: 3;
  }
}
</style>
