<template>
  <div class="travel-infos-container">
    <div class="trip-container">
      <h2 class="airport" v-if="!!plan.departureAirport">
        <font-awesome-icon :icon="'plane-departure'" class="airport-icon" />
        {{ plan.departureAirport.iata }}
      </h2>

      <h2 class="airport" v-else>
        <font-awesome-icon :icon="'plane-departure'" class="airport-icon" />
        {{ $t('header.from') }}
      </h2>

      <h4 class="travel-time">{{ formatTime(plan.departureTime) }}</h4>
      <h5 class="travel-date">{{ plan.departureDate }}</h5>
    </div>

    <div class="trip-stopover">
      <template v-if="!!plan.stopOver && plan.stopOver !== ''">
        <p>via {{ plan.stopOver }}</p>
      </template>

      <template v-else>
        <svg
          height="1.25rem"
          id="directFly"
          style="enable-background: new 0 0 340.034 340.034"
          version="1.1"
          viewBox="0 0 340.034 340.034"
          width="1.5rem"
          x="0px"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          y="0px"
        >
          <g>
            <polygon
              fill="white"
              points="222.814,52.783 200.902,74.686 280.748,154.528 0,154.528 0,185.513 280.748,185.513 200.902,265.353222.814,287.252 340.034,170.023"
            />
          </g>
        </svg>
      </template>
    </div>

    <div class="trip-container">
      <h2 class="airport" v-if="!!plan.arrivalAirport">
        <font-awesome-icon :icon="'plane-arrival'" class="airport-icon" />
        {{ plan.arrivalAirport.iata }}
      </h2>

      <h2 class="airport" v-else>
        <font-awesome-icon :icon="'plane-arrival'" class="airport-icon" />
        {{ $('header.to') }}
      </h2>

      <h4 class="travel-time">{{ formatTime(plan.arrivalTime) }}</h4>
      <h5 class="travel-date">{{ plan.arrivalDate }}</h5>
    </div>
  </div>
</template>

<script>
import formatTravelData from "../../../mixins/formatTravelData";

export default {
  name: "TimelineFutureElement",
  mixins: [formatTravelData],
  props: {
    plan: {
      type: Object,
    },
  },
  i18n: {
    messages: {
      fr: {
        header: {
          'from': "Départ",
          'to': 'Dest.'
        }
      },
      en: {
        header: {
          'from': 'From',
          'to': 'To'
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.travel-infos-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;

  .airport {
    &-icon {
      font-size: 1.2rem;
      margin-right: 0.25rem;
    }
  }

  .travel-date {
    color: $color-extra-light-blue;
  }

  .trip-stopover {
    font-style: italic;
  }
}
</style>
