<template>
  <div class="modal modal__backdrop" @click="checkClose">
    <div class="modal__content">
      <font-awesome-icon :icon="'times-circle'" class="modal__close" @click="$emit('close')"/>
      <slot name="content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  methods: {
    checkClose(event) {
      if (event.target.classList.contains('modal__backdrop')) {
        this.$emit('close');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.modal {


  &__backdrop {
    background-color: rgba(0, 0, 0, 0.49);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__content {
    z-index: 9999;
    color: black;
    padding-top: 50px;
    position: absolute;
    top: 50px;
    bottom: 50px;
    left: 20px;
    right: 20px;
    overflow: auto;
    border-radius: 10px;
    background: white;
    max-width: 600px;
    width: 80vw;
    margin: auto;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: black;
    fill: black;
  }

  div {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>