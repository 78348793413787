<template>
  <form>
    <section v-if="isOnline">
      <p class="input__label">{{ $t("preferences.title") }}</p>

      <check-box-option
        :icon="'map-marker-alt'"
        :prefix="'fas'"
        :title="$t('preferences.location')"
        v-model="location"
      />
      <check-box-option
        :icon="'bell'"
        :prefix="'far'"
        :title="$t('preferences.notifications.push')"
        v-model="notifications.push"
      />
      <check-box-option
        :icon="'bell'"
        :prefix="'far'"
        :title="$t('preferences.notifications.email')"
        v-model="notifications.email"
      />
      <check-box-option
        :icon="'bell'"
        :prefix="'far'"
        :title="$t('preferences.notifications.sms')"
        v-model="notifications.sms"
      />
    </section>

    <section>
      <p class="input__label">{{ $t("language.title") }}</p>

      <div class="flex-center -spaceBetween" style="margin-top: 0.8rem">
        <span class="ins-subtitle">
          {{ $t("language.switch") }}
        </span>
        <language-selector v-model="language" />
      </div>
    </section>

    <section>
      <p class="input__label">{{ $t("management.title") }}</p>
      <div class="flex-center -spaceBetween" style="margin-top: 0.8rem">
        <router-link :to="{name: 'DeleteAccount'}" class="link-simple">{{ $t("management.delete") }}</router-link>
      </div>
    </section>

  </form>
</template>

<script>
import CheckBoxOption from "../components/common/CheckBoxOption";
import LanguageSelector from "../components/LanguageSelector";
import { mapGetters, mapActions } from "vuex";
import DeleteAccount from "@/pages/DeleteAccount.vue";

export default {
  name: "Settings",
  components: { CheckBoxOption, LanguageSelector },
  data() {
    return {
      location: true,
      notifications: {
        sms: false,
        email: false,
        push: false,
      },
      language: "fr",
    };
  },
  beforeMount() {
    if (this.user.location) {
      this.location = this.user.location;
    }
    if (this.user.notifications) {
      this.notifications = this.user.notifications;
    }
    if (this.user.language) {
      this.language = this.user.language;
    }
  },
  computed: {
    DeleteAccount() {
      return DeleteAccount
    },
    ...mapGetters(["isOnline"]),
    ...mapGetters("user", {
      user: "currentUser",
    }),
  },
  methods: {
    ...mapActions("user", {
      _updateUser: "update",
    }),
    async updateUser() {
      await this._updateUser({
        location: this.location,
        notifications: this.notifications,
        language: this.language,
      });
    },
  },
  watch: {
    language(to, from) {
      if (to !== from) {
        this.$root.$i18n.locale = to;
        this.language = to;
        this.updateUser();
      }
    },
    notifications: {
      deep: true,
      handler() {
        this.updateUser();
      },
    },
    location() {
      this.updateUser();
    },
  },
  i18n: {
    messages: {
      fr: {
        preferences: {
          title: "Paramètres",
          location: "Localisation",
          notifications: {
            sms: "Notifications par SMS",
            email: "Notifications par courriel",
            push: "Notifications Push (si activé)",
          },
        },
        language: {
          title: "Affichage",
          switch: "Langue",
        },
        management: {
          title: "Gestion",
          delete: "Supprimer mon compte",
        },
      },
      en: {
        preferences: {
          title: "Settings",
          location: "Location",
          notifications: {
            title: "Notifications",
            sms: "SMS Notifications",
            email: "Emails Notifications",
            push: "Push Notifications",
          },
        },
        language: {
          title: "Display",
          switch: "Language",
        },
        management: {
          title: "Management",
          delete: "Delete my account",
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
section:not(:first-of-type) {
  margin-top: 2rem;
  padding-top: 2rem;
}
</style>
