<template>
  <div class="policy" v-html="getMarkdown()" />
</template>

<script>
import sanitizeHtml from 'sanitize-html';
import * as marked from "marked";

import FrenchVersion from 'raw-loader!../../assets/policies/terms-and-conditions-fr.md';
import EnglishVersion from 'raw-loader!../../assets/policies/terms-and-conditions-en.md';

export default {
  name: "TermsAndConditionsPolicy",
  data() {
    return {
      french: FrenchVersion,
      english: EnglishVersion
    }
  },
  methods: {
    getMarkdown() {
      return sanitizeHtml(marked.parse(this.$i18n.locale === 'fr' ? FrenchVersion : EnglishVersion));
    }
  }
}
</script>

<style scoped lang="scss">

</style>