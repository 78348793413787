<template>
  <tabs
    :tabs="tabs"
    :currentTab="currentTab"
    :wrapper-class="'default-tabs'"
    :tab-class="'default-tabs__item'"
    :tab-active-class="'default-tabs__item_active'"
    :line-class="'default-tabs__active-line'"
    @onClick="handleClick"
  />
</template>

<script>
import Tabs from "vue-tabs-with-active-line";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "TabsNavigation",
  components: {
    Tabs,
  },

  props: {
    currentTab: {
      type: String,
      default: "plan",
    },
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters("plans", ["allPlans"]),

    tabs() {
      let tabs = [];
      if (this.allPlans.length > 0) {
        tabs.push({
          order: 0,
          title: this.$t("tabs.plan"),
          value: "plan",
        });
        tabs.push({
          order: 2,
          title: this.$t("tabs.future"),
          value: "future",
        });
      }

      if (this.$route.name === "TravelEdit") {
        tabs.push({
          order: 1,
          title: this.$t("tabs.edit"),
          value: "create",
        });
      } else {
        tabs.push({
          order: 1,
          title: this.$t("tabs.create"),
          value: "create",
        });
      }

      return _.orderBy(tabs, "order");
    },
  },

  methods: {
    handleClick(newTab) {
      if (newTab !== this.currentTab) {
        this.$router.push({ path: `/travel/${newTab}` });
      }
    },
  },
};
</script>

<style scoped></style>
