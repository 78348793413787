import { DateTime } from "luxon";

export default {
  methods: {
    formatTime(timeString) {
      if (timeString) {
        return timeString.substring(0, 5);
      }
      else {
        return "XX:XX";
      }
    },
    formatLocalTime(date, time, timezone) {
      const _date = DateTime.fromFormat(date + " " + time.substr(0, 8), "yyyy-MM-dd HH:mm:ss").setZone(timezone, { keepLocalTime: true });
      return _date.toFormat("HH:mm");
    }
  }
};
