<template>
  <div>
    <label class="input__label" :class="{'input__label--full': full}" v-if="!!label">{{ label }}</label>
    <v-select
      :items="options"
      v-model="v"
      :hint="errorMessage"
      :persistent-hint="hasError"
      :value="v"
      item-text="text"
      item-value="value"
    />
  </div>
</template>

<script>
import $input from "./input-base.mixin";

export default {
  name: "input-select",
  mixins: [$input],
  props: {
    value: {
      type: [String, Number],
      default: () => null
    },
    options: {
      type: Array,
      default: () => []
    },
    label: {
      type: String
    },
    full: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      v: this.value
    };
  },

  watch: {
    v(to) {
      this.$emit("input", to);
    }
  },

  methods: {
    resetForm() {
      this.v = this.value;
    }
  }
};
</script>
