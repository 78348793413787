<template>
  <div class="travel-preview-container">
    <!--        From travel info-->
    <div class="travel-block">
      <h2
        v-if="!!planPreview.departureAirport"
        :class="{
          'airport-code': true,
          '-defaultFrom': planPreview.departureAirport === 'From',
        }"
      >
        {{ planPreview.departureAirport.iata }}
      </h2>
      <h2 v-else :class="{ 'airport-code': true, '-defaultFrom': true }">
        {{ $t("common.from") }}
      </h2>

      <h4>{{ formatTime(planPreview.departureTime) }}</h4>
      <h5 class="travel-date">
        {{
          !!planPreview.departureDate ? planPreview.departureDate : "YYYY-MM-DD"
        }}
      </h5>
    </div>

    <!--        To travel info-->
    <div class="travel-block">
      <h2
        v-if="!!planPreview.arrivalAirport"
        :class="{
          'airport-code': true,
          '-defaultTo': planPreview.arrivalAirport === 'To',
        }"
      >
        {{ planPreview.arrivalAirport.iata }}
      </h2>
      <h2 v-else :class="{ 'airport-code': true, '-defaultTo': true }">
        {{ $t("common.to") }}
      </h2>

      <h4>{{ formatTime(planPreview.arrivalTime) }}</h4>
      <h5 class="travel-date">
        {{ !!planPreview.arrivalDate ? planPreview.arrivalDate : "YYYY-MM-DD" }}
      </h5>
    </div>
  </div>
</template>

<script>
import formatTravelData from "../../mixins/formatTravelData";

export default {
  name: "TravelPreview",
  mixins: [formatTravelData],
  props: {
    planPreview: {
      type: Object,
      required: false,
      default: () => ({
        departureAirport: null,
        departureDate: null,
        departureTime: "XX:XX",
        arrivalAirport: null,
        arrivalDate: null,
        arrivalTime: "XX:XX",
      }),
    },
  },
};
</script>

<style scoped lang="scss">
.travel {
  &-preview-container {
    display: flex;
    justify-content: space-around;
    width: 256px;
    margin: 2rem auto 0;
    max-width: 400px;

    box-sizing: border-box;
    border-width: 0 2px 2px 2px;
    border-style: solid;
    padding: 1rem;
  }

  &-block {
    text-align: center;

    .airport-code {
      text-transform: uppercase;
      margin-top: -2rem;
      letter-spacing: 0.05rem;
      padding: 0 1rem;
      position: relative;
      width: 8rem;

      &:before,
      &:after {
        content: "";
        position: absolute;
        /*height: 2px;*/
        background: $color-white;
        top: 1rem;
        /*top: 50%;*/
        width: 2rem;
        height: 2px;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }

      &.-defaultFrom {
        &:before,
        &:after {
          width: 1.3rem;
        }
      }

      &.-defaultTo {
        &:before,
        &:after {
          width: 1.6rem;
        }
      }
    }

    .travel-date {
      color: $color-extra-light-blue;
    }
  }
}
</style>
