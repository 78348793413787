<template>
  <div>
    <form
      @submit.prevent="noop"
      v-on:keyup.enter="validateBeforeSubmit"
      action=""
    >
      <input-email :errors="$v.email" v-model="email" />
      <input-password :errors="$v.password" v-model="password" />

      <div class="buttons-container">
        <router-link
          :to="{ name: 'ForgotPassword' }"
          class="link-simple"
          tag="a"
        >
          {{ $t("buttons.forgotPassword") }}
        </router-link>
        <button
          class="button__primary -filled"
          type="button"
          @click="validateBeforeSubmit"
          style="cursor: pointer"
        >
          {{ $t("buttons.login") }}
        </button>
      </div>

      <div class="signup-cta-section">
        <router-link
          :to="{ name: 'Register' }"
          class="link-simple colorLightBlue underline"
          tag="a"
        >
          {{ $t("buttons.notMember") }} {{ $t("buttons.register") }}
        </router-link>
      </div>

      <section>
        <div
          class="flex-center -spaceBetween language-switcher"
          style="margin-top: 2.5rem"
        >
          <span class="ins-subtitle">
            {{ $t("language.switch") }}
          </span>
          <language-selector v-model="language" />
        </div>
      </section>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import InputEmail from "../components/common/inputs/InputEmail";
import InputPassword from "../components/common/inputs/InputPassword";
import ApiRequestFailedException from "../exceptions/ApiRequestFailedException";
import { email, minLength, required } from "vuelidate/lib/validators";
import LanguageSelector from "../components/LanguageSelector";

export default {
  name: "Login",
  components: {
    LanguageSelector,
    InputEmail,
    InputPassword,
  },
  data() {
    let email = null;
    let password = null;
    return {
      email,
      password,
      loading: false,
      language: "fr",
    };
  },

  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(6),
    },
  },

  async mounted() {
    // If verification token is in the URL, try to confirm the account
    if (this.$route.query.token) {
      await this.confirmAccountEmail(this.$route.query.token);
    }
  },

  computed: {
    ...mapGetters("user", {
      currentUser: "currentUser",
    }),
  },

  methods: {
    ...mapActions("user", [
      "login",
      "verifyWithToken",
      "resendConfirmationEmail",
    ]),
    ...mapActions({
      fetchAirports: "airports/fetchAirports",
      fetchPlans: "plans/fetchPlans",
    }),

    handleInput(value) {
      this.$emit("input", value);
    },

    noop() {
      // Empty
    },

    async validateBeforeSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.error("INVALID FORM", this.$v);
        return;
      }
      return await this.connectUser({
        email: this.email,
        password: this.password,
      });
    },

    /**
     * Login the user.
     * @param data
     * @returns {Promise<void>}
     */
    async connectUser(data) {
      try {
        this.loading = this.$loading.show({
          loader: "spinner",
          color: "white",
        });
        let blocked = !(await this.login(data));

        if (blocked) {
          // User was blocked from API
          this.$swal({
            text: this.$t("errors.blocked"),
            icon: "error",
            allowOutsideClick: true,
            allowEscapeKey: true,
            showDenyButton: true,
          });
          return;
        }

        // After user login, fetch all its Travel and Account information
        await this.fetchAirports();
        await this.fetchPlans();

        this.loading.hide();

        // If AMES score is 0, redirect it to the AMES form.
        // Otherwise, to the travels
        if (
          this.currentUser.wakeUpTime === null ||
          this.currentUser.bedTime === null
        ) {
          this.$router.push({ name: "Profile" });
        }
        if (
          !this.currentUser.ames_answers ||
          this.currentUser.ames_answers.total === 0
        ) {
          this.$router.push({ name: "AMES" });
        } else {
          this.$router.push({ name: "TravelPlan" });
        }
      } catch (error) {
        this.loading.hide();
        console.log("inside catch", error);

        // Prepare error modal
        if (error instanceof ApiRequestFailedException) {
          if (error.errorCode === "Auth.form.error.confirmed") {
            let modalResult = await this.$swal({
              text: this.$t("login.errors.not-confirmed"),
              icon: "error",
              showCancelButton: true,
              cancelButtonText: this.$t("buttons.close"),
              showConfirmButton: true,
              confirmButtonText: this.$t(
                "login.need-email-confirmation.button"
              ),
            });
            if (modalResult.isConfirmed) {
              await this.resendConfirmationEmail(this.email);
            }
            return;
          } else if (error.errorCode === "Auth.form.error.invalid") {
            await this.$swal({
              text: this.$t("login.errors.bad-credentials"),
              icon: "error",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: this.$t("buttons.close"),
            });
          } else if(error.errorCode === "Auth.form.error.blocked") {
            await this.$swal({
              text: this.$t("login.errors.blocked"),
              icon: "error",
              showConfirmButton: false,
              showCancelButton: true,
              cancelButtonText: this.$t("buttons.close"),
            });
          }
          return;
        }
        await this.$swal({
          text: this.$t("login.errors.unknown"),
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: this.$t("buttons.close"),
        });
      }
    },

    /**
     * Confirm the account email with the confirmation token.
     * @param {string} token
     * @returns {Promise<void>}
     */
    async confirmAccountEmail(token) {
      try {
        await this.verifyWithToken(token);
        // Success
        await this.$swal({
          text: this.$t("confirm-email.success.text"),
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });
      } catch (e) {
        await this.$swal({
          text: this.$t("confirm-email.error.text"),
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });
      }

      // Remove the token from the URL
      let query = Object.assign({}, this.$route.query);
      delete query.token;
      try {
        this.$router.replace({ query });
      } catch (e) {
        // Ignore
      }
    },
  },
  watch: {
    language(to) {
      this.$root.$i18n.locale = to;
    },
  },
  i18n: {
    messages: {
      fr: {
        close: "Fermer",
        error: "Erreur",
        "confirm-email": {
          success: {
            title: "Activation",
            text: "Votre compte a été validé. Vous pouvez maintenant vous connecter à votre nouveau compte.",
          },
          error: {
            title: "Erreur",
            text: "Ce lien d'activation a déjà été utilisé ou n'existe pas. Veuillez contacter le support en cas de problème.",
          },
        },
        login: {
          "need-email-confirmation": {
            button: "Renvoyer le courriel de confirmation",
          },
          errors: {
            title: "Erreur",
            "not-confirmed": "Vous devez confirmer votre adresse courriel avant de pouvoir vous connecter.",
            "bad-credentials": "Adresse courriel et/ou mot de passe non reconnu. Veuillez modifier vos informations de connexion et réessayer.",
            blocked: "Votre compte a été désactivé par un administrateur. Veuillez contacter le support pour pouvoir réactiver votre compte.",
            unknown: "Une erreur est survenue lors de la tentative de connexion. Veuillez réessayer plus tard et vous assurer d'avoir accès à internet.",
          },
        },
        language: {
          title: "Affichage",
          switch: "Changer la langue à",
        },
      },
      en: {
        close: "Close",
        error: "Error",
        "confirm-email": {
          success: {
            title: "Activation success",
            text: "Your account has been validated successfully. You can now login into your new account.",
          },
          error: {
            title: "Error",
            text: "This activation link has already been used or does not exist. Please contact support if there is any problem.",
          },
        },
        login: {
          "need-email-confirmation": {
            button: "Resend confirmation email",
          },
          errors: {
            title: "Error",
            "not-confirmed": "You must confirm your email address before you can log in.",
            "bad-credentials": "Email address and / or password not recognized. Please change your login information and try again.",
            blocked: "Your account has been deactivated by an administrator. Please contact support to be able to reactivate your account.",
            unknown: "An error occurred while trying to connect. Please try again later and make sure you have internet access.",
          },
        },
        language: {
          title: "Display",
          switch: "change Language to",
        },
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.link-simple {
  font-size: 0.8rem !important;
}

@media screen and (max-width: 373px) {
  .link-simple {
    font-size: 0.7rem !important;
  }
}

.signup-cta-section {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid $color-dark-gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-switcher {
  max-width: 400px;
  margin: 1rem auto 2rem auto;
  left: 20px;
  right: 20px;

  @media screen and (max-width: 649px) {
    position: absolute;
    bottom: 100px;
  }

  @media screen and (min-width: 650px) {
    margin: 4rem auto;
  }
}
</style>
