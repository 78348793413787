<template>
  <div data-app>
    <label v-if="label" class="input__label">{{ label }}</label>
    <v-slider
      v-model="v"
      :tick-labels="options"
      :max="3"
      step="1"
      ticks="always"
      tick-size="4"
      :inverse-label="true"
    />
  </div>
</template>

<script>
export default {
  name: "InputProgram",
  props: {
    value: {
      type: Number,
      default: () => 2
    },
    label: {
      type: String
    }
  },
  data() {
    return {
      v: this.value,
      options: [
        "3 jours",
        "2 jours",
        "1 jour",
        "0 (Décollage)"
      ].reverse()
    };
  },
  watch: {
    v(to) {
      this.$emit("input", to);
    }
  }
};
</script>

<style scoped>

</style>
