<template>
  <div v-if="travel">
    <template v-for="item in travel.timeline">
      <timeline-element :day-details="item" :key="item.id" />
    </template>
  </div>
</template>

<script>
import TimelineElement from "./timeline/TimelineElement";

export default {
  name: "PlanTabContent",
  components: { TimelineElement },
  props: {
    travel: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .timeline-element:last-of-type {
  .timeline-line.collapsed {
    visibility: hidden;
  }
}
</style>
