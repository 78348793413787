<template>
  <div>
    <form @submit.prevent="noop" v-on:keyup.enter="validateBeforeSubmit">
      <!-- Email to send forgot password's email -->
      <input-email :errors="$v.email" v-model.trim="email" />
      <p class="instructions">{{ $t("instructions-text") }}</p>

      <!-- Actions -->
      <div class="buttons-container">
        <router-link :to="{ name: 'Login' }" class="link-simple">
          {{ $t("buttons.backToLogin") }}
        </router-link>
        <button
          class="button__primary -filled"
          type="button"
          @click="validateBeforeSubmit"
        >
          {{ $t("buttons.send") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import InputEmail from "../components/common/inputs/InputEmail";
import ApiRequestFailedException from "../exceptions/ApiRequestFailedException";
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "forgot-password",
  components: {
    InputEmail,
  },
  data() {
    return {
      email: null,
      backToHome: false,
      loading: null,
    };
  },

  validations: {
    email: {
      required,
      email,
    },
  },

  methods: {
    ...mapActions("user", ["forgotPassword"]),

    noop() {
      // Empty
    },

    /**
     * Validate
     */
    async validateBeforeSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.error("INVALID FORM", this.$v);
        return;
      }
      return await this.sendForgotPasswordEmail();
    },

    /**
     * Send the forgot password email.
     * @returns {Promise<void>}
     */
    async sendForgotPasswordEmail() {
      this.loading = this.$loading.show({
        loader: "spinner",
        color: "white",
      });

      try {
        // Send the email
        await this.forgotPassword(this.email);

        this.loading.hide();

        // Show success message
        await this.$swal({
          text: this.$t("success.message"),
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });

        await this.$router.push({ name: "Login" });
      } catch (err) {
        this.loading.hide();

        let message;

        // Api error
        if (err instanceof ApiRequestFailedException) {
          switch (err.errorCode) {
            case "Auth.form.error.invalid":
              message = this.$t("errors.emailInvalid");
              break;
            case "Auth.form.error.not-found":
              message = this.$t("errors.emailNotFound");
              break;
          }
        }

        // Unknown error
        if (!message) {
          message = this.$t("errors.unknown");
        }

        // Show error
        await this.$swal({
          text: message,
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });
      }
    },
  },
  i18n: {
    messages: {
      fr: {
        buttons: {
          backToLogin: "Retour à la connexion",
        },
        success: {
          title: "Succès",
          message:
            "Un courriel pour modifier votre mot de passe a été envoyé. Vous devriez le recevoir d'ici quelques minutes.",
        },
        error: {
          title: "Erreur",
        },
        errors: {
          emailInvalid: "Veuillez fournir une adresse courriel valide.",
          emailNotFound: "Aucun compte n'est lié à cette adresse courriel.",
          unknown:
            "Une erreur inconnue est survenue. Veuillez réessayer plus tard.",
        },
        "instructions-text":
          "Vous recevrez un lien par courriel afin de réinitialiser votre mot de passe.",
      },
      en: {},
    },
  },
};
</script>

<style scoped lang="scss">
p.instructions {
  margin-top: 2rem;
  font-size: 0.9rem;
}
</style>
