import Vue from "vue";
import axios from "axios";
import _ from "lodash";

import AccountBlockedByAdministratorException from "../../exceptions/AccountBlockedByAdministratorException";
import ApiRequestFailedException from "../../exceptions/ApiRequestFailedException";
import * as Sentry from "@sentry/vue";

let handleError = (error) => {
  if (error.response.data) {
    throw new ApiRequestFailedException(
      error.response.statusCode,
      error.response.data.data[0].messages[0].id,
      error.response.data.data[0].messages[0].message,
      error.response.data
    );
  }
  throw error;
};

export default {
  namespaced: true,
  state: () => {
    return {
      token: null,
      user: null
    };
  },
  mutations: {
    setUser(state, { user, token }) {
      Vue.set(state, "user", user);
      if (token) {
        Vue.set(state, "token", token);
      }
    },
    logout(state) {
      Vue.set(state, "user", null);
      Vue.set(state, "token", null);
    }
  },
  getters: {
    currentUser: state => {
      return state.user;
    },
    isConnected: state => {
      return state.user !== null && state.token !== null;
    },
    getToken: state => {
      return state.token;
    }
  },
  actions: {
    /**
     * Login the user.
     * @param commit
     * @param dispatch
     * @param email
     * @param password
     */
    async login({ commit }, { email, password }) {
      let response;
      try {
        response = await axios.post(`/auth/local`, {
          identifier: email,
          password
        }, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json"
          },
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return JSON.stringify(data);
          }
        });
      } catch (error) {
        console.error("Failed to login in", error);
        // Handle error
        handleError(error);
      }

      // Handle response
      if (response.data.user.blocked) {
        return false;
      }

      commit("setUser", {
        token: response.data.jwt,
        user: response.data.user
      });

      // Sentry
      Sentry.setUser({ email: response.data.user.email });

      return true;
    },

    /**
     * Logout the current user.
     * @param commit
     * @param state
     * @returns {Promise<void>}
     */
    async logout({ commit, state }) {
      if (state.user) {
        Sentry.configureScope(scope => scope.setUser(null));
        commit("logout");

        if (window.cordova) {
          // Remove all notifications
          window.cordova.notification.local.cancelAll();
        }
      }
    },

    /**
     * Register the user.
     * @param unused
     * @param data
     * @returns {Promise<null|number|PublicKeyCredentialUserEntity|*>}
     */
    async register(unused, data) {
      let response;
      try {
        response = await axios.post(`/auth/local/register`, {
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName
        }, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json"
          },
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return JSON.stringify(data);
          }
        });
      } catch (error) {
        console.error("Failed to login in", error);
        // Handle error
        handleError(error);
      }

      // Handle response
      if (response.data.blocked) {
        throw new AccountBlockedByAdministratorException();
      }

      return response.data;
    },

    /**
     * Verify the activation token.
     *
     * @param tools
     * @param {string} token
     *
     * @returns {Promise<boolean>}
     */
    async verifyWithToken(tools, token) {
      try {
        await axios.get(`/auth/email-confirmation?token=${token}`, {
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return data;
          }
        });
      } catch (error) {
        console.error("Failed to verify user", error);
        // Handle error
        handleError(error);
      }

      return true;
    },

    /**
     * Update the current user.
     * @param commit
     * @param state
     * @param data
     * @returns {Promise<null|number|PublicKeyCredentialUserEntity|*>}
     */
    async update({ commit }, data) {
      let response;

      if (data.bedTime && data.bedTime.length <= 11) {
        data.bedTime = "0" + data.bedTime.toString();
      }
      if (data.wakeUpTime && data.wakeUpTime.length <= 11) {
        data.wakeUpTime = "0" + data.wakeUpTime.toString();
      }

      try {
        response = await axios.patch(`/users/me`, {
          email: data?.email,
          firstName: data?.firstName,
          lastName: data?.lastName,
          city: data.city ?? (_.has(data, "city") ? null : undefined),
          items: data.items ?? (_.has(data, "items") ? null : undefined),
          bedTime: data.bedTime ?? (_.has(data, "bedTime") ? null : undefined),
          wakeUpTime: data.wakeUpTime ?? (_.has(data, "wakeUpTime") ? null : undefined),
          ames_answers: data.ames_answers ?? (_.has(data, "ames_answers") ? null : undefined),
          language: data.language ?? undefined,
          location: data.location ?? undefined,
          notifications: data.notifications ?? undefined
        });
        // Handle response
        commit("setUser", {
          user: response.data
        });
        return response.data.user;
      } catch (error) {
        console.error("Failed to login in", error);
        // Handle error
        handleError(error);
      }
    },

    /**
     * Refresh current user.
     * @param commit
     * @param state
     * @returns {Promise<null|*|string|number|PublicKeyCredentialUserEntity>}
     */
    async refreshUser({ commit }) {
      let response;
      try {
        response = await axios.get(`/users/me`);
      } catch (error) {
        // commit("logout"); -- Allow offline support
        console.error("Failed to refresh user", error);
        // Handle error
        handleError(error);
      }

      // Handle response
      commit("setUser", {
        user: response.data
      });

      return response.data.user;
    },

    /**
     * Send a forgot password request email to the given email address.
     * @param tools
     * @param {string} email
     * @returns {Promise<null|*|string|number|PublicKeyCredentialUserEntity>}
     */
    async forgotPassword(tools, email) {
      try {
        await axios.post(`/auth/forgot-password`, {
          email
        }, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json"
          },
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return JSON.stringify(data);
          }
        });
      } catch (error) {
        console.error("Failed to send forgot password", error);
        // Handle error
        handleError(error);
      }

      return true;
    },

    /**
     * Send the reset password request to backend. Should update the user password.
     * @param tools
     *
     * @param token
     * @param password
     *
     * @returns {Promise<boolean>}
     */
    async resetPassword(tools, { token, password }) {
      try {
        await axios.post(`/auth/reset-password`, {
          code: token,
          password,
          passwordConfirmation: password
        }, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json"
          },
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return JSON.stringify(data);
          }
        });
      } catch (error) {
        console.error("Failed to send reset password", error);
        // Handle error
        handleError(error);
      }

      return true;
    },

    /**
     * Resend the confirmation email.
     *
     * @param tools
     * @param email
     *
     * @returns {Promise<boolean>}
     */
    async resendConfirmationEmail(tools, email) {
      try {
        await axios.post(`/auth/resend-confirmation-email`, {
          email
        }, {
          headers: {
            // Overwrite Axios's automatically set Content-Type
            "Content-Type": "application/json"
          },
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return JSON.stringify(data);
          }
        });
      } catch (error) {
        console.error("Failed to resend confirmation email", error);
        // Handle error
        handleError(error);
      }

      return true;
    },

    async updateProfilePicture({ commit }, formData) {
      try {
        let user = await axios.post(`/user/picture`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        commit("setUser", { user: user.data });
      } catch (error) {
        console.error("Failed to resend confirmation email", error);
        // Handle error
        handleError(error);
      }
    },

    /**
     * Send current push token to the backend.
     * @param tools
     * @param token
     * @param platform
     * @returns {Promise<void>}
     */
    async sendPushToken({ state }, { token, platform }) {
      if (state.user) {
        try {
          await axios.post(`/user/push`, {
            token, platform
          });
        } catch (error) {
          console.error("Failed to send push token to backend", error);
          // Handle error
          handleError(error);
        }
      }
    },

    /**
     * Ask backend to remove the push token for user.
     * @param tools
     * @param token
     * @param platform
     * @returns {Promise<void>}
     */
    async removePushToken({ state }, { token, platform }) {
      if (state.user) {
        try {
          await axios.delete(`/user/push`, {
            token, platform
          });
        } catch (error) {
          console.error("Failed to delete push token", error);
          // Handle error
          handleError(error);
        }
      }
    },

    /**
     * Delete the current user.
     * @returns {Promise<null|*|string|number|PublicKeyCredentialUserEntity>}
     * @param state
     * @param data
     */
    // eslint-disable-next-line no-unused-vars
    async delete({ state }, data) {
      try {
        await axios.post(`/delete-requests`, data, {
          headers: {
            "Content-Type": "application/json"
          },
          transformRequest: (data, headers) => {
            delete headers.common["Authorization"];
            return JSON.stringify(data);
          }
        });
      } catch (error) {
        console.error("Failed to delete user", error);
        // Handle error
        //handleError(error);
        throw error;
      }
    }
  }
};
