export default {
    methods: {

        /**
         * Resolve the name for the timeline element.
         *
         * @param timelineEntry
         * @param user
         *
         * @returns {VueI18n.TranslateResult|string}
         */
        resolveName(timelineEntry, user) {
            if (timelineEntry.symbol === "c") {
                return (
                    this.$t("actions.constraint") + ": " + this.$t("actions." + timelineEntry.action.toLowerCase())
                );
            }
            let text = this.$t("actions." + timelineEntry.action.toLowerCase());
            if (
                user.items &&
                user.items.blueLight === true &&
                timelineEntry.action.indexOf("LIGHT_IDEAL") > -1
            ) {
                text += " " + this.$t("actions.blue_light_on");
            }
            return text;
        }
    }
}