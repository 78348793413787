<template>
  <div>
    <div class="input__withIcon">
      <InputText
        :placeholder="$t('inputs.password.placeholder')"
        :type="type"
        @blur="$emit('blur')"
        @focus="focus"
        class="-withIcon"
        v-model="password"
        :errors="errors"
        :label="$t('inputs.password.label')"
      />

      <slot :toggle="togglePassword" name="password-toggle">
        <button
          :title="toggleMessage()"
          @click="togglePassword"
          class="togglePassword-button"
          type="button"
        >
          <slot name="password-hide" v-if="type === 'text'">
            <svg
              class="VuePassword__Toggle-Icon VuePassword__Toggle-Icon--hide"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.81 4.36l-1.77 1.78a4 4 0 0 0-4.9 4.9l-2.76 2.75C2.06 12.79.96 11.49.2 10a11 11 0 0 1 12.6-5.64zm3.8 1.85c1.33 1 2.43 2.3 3.2 3.79a11 11 0 0 1-12.62 5.64l1.77-1.78a4 4 0 0 0 4.9-4.9l2.76-2.75zm-.25-3.99l1.42 1.42L3.64 17.78l-1.42-1.42L16.36 2.22z"
              />
            </svg>
          </slot>
          <slot name="password-show" v-else>
            <svg
              class="VuePassword__Toggle-Icon VuePassword__Toggle-Icon--show"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
              />
            </svg>
          </slot>
        </button>
      </slot>
    </div>
  </div>
</template>

<script>
import $input from "./input-base.mixin";
import InputText from "./InputText";

export default {
  name: "InputPassword",
  mixins: [$input],
  components: { InputText },

  props: {
    label: {
      type: String
    },
    referenceString: {
      type: String,
      default: "password"
    }
  },

  data() {
    return {
      password: "",
      type: "password"
    };
  },

  watch: {
    password: {
      handler: function() {
        this.$emit("input", this.password);
      },
      deep: true
    }
  },

  methods: {
    toggleMessage() {
      return this.type === "password" ? "Show Password" : "Hide Password";
    },

    /**
     * Toggle the visibilty of the password.
     */
    togglePassword() {
      this.type = this.type === "password" ? "text" : "password";
    }
  }
};
</script>

<style lang="scss" scoped>
.togglePassword-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.1rem;
  background-color: inherit;
  border: none;
}

.VuePassword__Toggle-Icon {
  fill: $color-medium-gray;
  cursor: pointer;
  pointer-events: none;
}
</style>
