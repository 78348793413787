<template>
  <div>
    <travel-preview v-if="travelPlan" :plan-preview="travelPlan" />
    <travel-preview v-else />

    <!--    Tab Section-->
    <section>
      <tabs-navigation :current-tab="'plan'" />

      <div class="tabs__content">
        <tab-content-plan v-if="travelPlan" :travel="travelPlan" />
        <p v-else>{{ $t("no-plan") }}</p>
      </div>
    </section>
  </div>
</template>

<script>
import TravelPreview from "../components/travelPlans/TravelPreview";
import TabsNavigation from "../components/travelPlans/TabsNavigation";
import TabContentPlan from "../components/travelPlans/TabContentPlan";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "TravelPlan",
  components: {
    TravelPreview,
    TabsNavigation,
    TabContentPlan,
  },
  props: {
    travel: {
      type: Object,
    },
  },
  data() {
    return {
      travelPlan: null,
    };
  },

  computed: {
    ...mapGetters({
      plans: "plans/allPlans",
    }),
  },

  async mounted() {
    if (this.travel) {
      this.travelPlan = this.travel;
    } else {
      if (window.navigator.onLine) {
        try {
          this.loading = this.$loading.show({
            loader: "spinner",
            color: "white",
          });
          try {
            await this.fetchPlans();
          } catch (e) {
            if (e.message === "not-logged-in") {
              this.logout();
              window.location.href = "/";
              window.localStorage.removeItem("vuex");
            }
          }
        } finally {
          this.loading.hide();
        }
      }
      if (this.plans.length > 0) {
        this.travelPlan = this.plans[0];
      } else {
        await this.$router.push({ name: "TravelCreate" });
      }
    }
  },
  methods: {
    ...mapActions({
      fetchPlans: "plans/fetchPlans",
    }),
  },
  i18n: {
    messages: {
      fr: {
        "no-plan": "Aucun plan en cours ou futur. Veuillez créer un plan.",
      },
      en: {
        "no-plan": "No current or future plan. Please create a plan.",
      },
    },
  },
};
</script>

<style lang="scss"></style>
