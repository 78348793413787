import Vue from "vue";
import axios from "axios";
import Fuse from "fuse.js";

// Start the search index
let index = new Fuse([], {
  threshold: 0.2,
  distance: 100,
  includeScore: true,
  // Search in `author` and in `tags` array
  //keys: ["name", "city", "iata"],
  keys: [
    {
      name: "name",
      weight: 0.5
    },
    {
      name: "iata",
      weight: 1
    },
    {
      name: "city",
      weight: 0.3
    },
    {
      name: "country",
      weight: 0.1
    }
  ]
});

export default {
  namespaced: true,
  state: () => {
    return {
      airports: {}
    };
  },
  mutations: {
    /**
     * Add a new airport or update an existing airport.
     * @param {object} state
     * @param {object[]} airports
     */
    setAirports(state, airports) {
      let data = {};
      for (const airport of airports) {
        data[airport.iata] = airport;
        index.add(airport);
      }
      Vue.set(state, "airports", data);
    }
  },
  getters: {
    /**
     * Return all the airports.
     * @param {object} state
     * @returns {object[]}
     */
    allAirports: state => {
      return Object.values(state.airports);
    },
    /**
     * Get the airport with the given IATA.
     * @param {object} state
     * @returns {function(string): object|undefined}
     */
    getWithIata: state => iata => {
      return state.airports[iata];
    },
    /**
     * Search for an existing airport.
     * @returns {function(string): object[]}
     */
    searchAirport: () => (searchText) => {
      console.log('Search airport', searchText);
      return index.search(searchText);
    }
  },
  actions: {
    /**
     * Fetch all plans for the current user.
     * @param {function} commit
     * @returns {Promise<void>}
     */
    async fetchAirports({ commit }) {
      let response;
      try {
        response = await axios.get(`/airports/simple?_start=0&_limit=7000`);
      }
      catch (error) {
        console.error("Failed to fetch the airports");
        throw new Error(error.response.data.message);
      }

      // Add plan to state
      commit("setAirports", response.data);
    }
  }
};
