<template>
  <div>
    <label class="input__label" :class="{'input__label--full': full}" v-if="!!label">{{ label }}</label>

    <div class="input__container">
      <v-autocomplete
        v-model="selectedAirport"
        :items="airports"
        item-text="name"
        item-value="iata"
        :search-input.sync="searchInput"
        :hint="errorMessage"
        :persistent-hint="hasError"
        :label="label"
        no-filter
        @change="emitAirportChanged()"
        single-line
        :append-icon="null"
        :placeholder="placeholder || $t('airport.placeholder')"
      >
        <template v-slot:selection="data">
          <div style="margin-right: 12px;" @click="resetForm()">
            <font-awesome-icon :icon="'times-circle'" />
          </div>
          <span style="font-size: 12px; width: 100%">
            {{ data.item.iata }}, {{ data.item.name }}, {{ data.item.city }}
          </span>
        </template>
        <template v-slot:item="data" @click="forceSelectAirport(data)">
          {{ data.item.iata }}, {{ data.item.name }}, {{ data.item.city }}
        </template>
      </v-autocomplete>
    </div>

  </div>
</template>

<script>

import $input from "./input-base.mixin";
import randomId from "random-entities/id";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "InputAirport",
  mixins: [$input],
  data() {
    return {
      id: randomId(),
      isLoading: false,
      searchInput: "",
      airports: [],
      selectedAirport: null
    };
  },

  props: {
    label: {
      type: String,
      optional: true
    },

    placeholder: {
      type: String,
      optional: true
    },

    value: {
      type: Object
    },

    full: {
      type: Boolean,
      required: false,
      default: false
    }

  },

  mounted() {
    !(_.isUndefined(this.value)) ? this.selectedAirport = this.value : this.selectedAirport = null;
    if (!this.selectedAirport) {
      this.airports = this._allAirports;
    }
    else {
      this.airports = _.map(this._searchAirport(this.selectedAirport.iata), i => i.item);
    }

    if (this.value) {
      this.selectedAirport = this.value.iata;
    }
  },

  computed: {

    ...mapGetters({
      _allAirports: "airports/allAirports",
      _searchAirport: "airports/searchAirport"
    }),

    getDefaultValue() {
      let v = "";
      if (this.selectedAirport) {
        v += this.selectedAirport.iata;
        v += ", " + this.selectedAirport.name;
        if (_.isObject(this.selectedAirport.city)) {
          v += ", " + this.selectedAirport.city.name;
        }
        else {
          v += ", " + this.selectedAirport.city;
        }
        if (_.isObject(this.selectedAirport.city.country)) {
          v += ", " + this.selectedAirport.city.country.name;
        }
        else {
          v += ", " + this.selectedAirport.city.country;
        }
      }
      return this.selectedAirport ? v : null;
    }
  },

  methods: {
    search(input) {
      console.log("SEARCH", input);
      if (input.length < 1) {
        return [];
      }
      return _.map(this.searchAirport(input), e => e.item);
    },

    getResultValue(result) {
      return `${result.iata}, ${result.name}, ${result.city}, ${result.country}`;
    },

    forceSelectAirport(airport) {
      console.log('AIRPORT CLICKED', airport);
      this.selectedAirport = airport;
      this.emitAirportChanged();
    },

    emitAirportChanged() {
      console.log('CHANGED AIRPORT', this.selectedAirport);
      this.$emit("airport-selected", this.selectedAirport);
    },

    resetForm() {
      this.selectedAirport = null;
    }
  },

  watch: {
    searchInput(to) {
      if (to === "" || !to) {
        this.airports = this._allAirports;
      }
      else {
        this.airports = _.map(this._searchAirport(this.searchInput), i => i.item);
      }
    },
    selectedAirport(to) {
      // Find
      let airport = _.find(this._allAirports, a => a.iata === to);
      if (airport) {
        this.$emit("input", airport);
      }
      else {
        this.$emit("input", null);
      }
    }
  },
  i18n: {
    messages: {
      fr: {
        airport: {
          placeholder: "Veuillez écrire le code ou le nom de l'aéroport"
        }
      },
      en: {
        airport: {
          placeholder: "Please enter the airport code or name"
        }
      }
    }
  }
};
</script>

<style lang="scss">

</style>
