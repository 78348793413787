var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"travel-preview-container"},[_c('div',{staticClass:"travel-block"},[(!!_vm.planPreview.departureAirport)?_c('h2',{class:{
        'airport-code': true,
        '-defaultFrom': _vm.planPreview.departureAirport === 'From',
      }},[_vm._v(" "+_vm._s(_vm.planPreview.departureAirport.iata)+" ")]):_c('h2',{class:{ 'airport-code': true, '-defaultFrom': true }},[_vm._v(" "+_vm._s(_vm.$t("common.from"))+" ")]),_c('h4',[_vm._v(_vm._s(_vm.formatTime(_vm.planPreview.departureTime)))]),_c('h5',{staticClass:"travel-date"},[_vm._v(" "+_vm._s(!!_vm.planPreview.departureDate ? _vm.planPreview.departureDate : "YYYY-MM-DD")+" ")])]),_c('div',{staticClass:"travel-block"},[(!!_vm.planPreview.arrivalAirport)?_c('h2',{class:{
        'airport-code': true,
        '-defaultTo': _vm.planPreview.arrivalAirport === 'To',
      }},[_vm._v(" "+_vm._s(_vm.planPreview.arrivalAirport.iata)+" ")]):_c('h2',{class:{ 'airport-code': true, '-defaultTo': true }},[_vm._v(" "+_vm._s(_vm.$t("common.to"))+" ")]),_c('h4',[_vm._v(_vm._s(_vm.formatTime(_vm.planPreview.arrivalTime)))]),_c('h5',{staticClass:"travel-date"},[_vm._v(" "+_vm._s(!!_vm.planPreview.arrivalDate ? _vm.planPreview.arrivalDate : "YYYY-MM-DD")+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }