<template>
  <div class="locale-changer">
    <div v-for="lang in langs" :key="lang">
      <a
        @click="$emit('input', lang.locale)"
        v-if="$root.$i18n.locale !== lang.locale"
        >{{ lang.name }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "language-selector",
  props: {
    value: String,
  },
  data() {
    return {
      langs: [
        {
          locale: "fr",
          name: "Français",
        },
        {
          locale: "en",
          name: "English",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.locale-changer {
  //background-color: #041428;
  //border-radius: 1rem;
  padding: 0.8rem 1.5rem;
  border: 1px solid $color-input-gray;
  cursor: pointer;
}

@media screen and (max-width: 373px) {
  .locale-changer {
    padding: 0.5rem 1.0rem;
  }
}
</style>
