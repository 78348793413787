<template>
  <div class="alert">
    <slot />
  </div>
</template>

<script>
export default {
  name: "AlertBanner",
};
</script>

<style lang="scss" scoped>
.alert {
  text-align: center;
  min-height: 30px;
  border: 1px solid darken($color-delete, 10);
  background-color: $color-delete;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto 20px auto;
}
</style>
