<template>
  <div>
    <travel-preview v-if="plan" :plan-preview="plan" />
    <travel-preview v-else />

    <!--    Tab Section-->
    <section>
      <tabs-navigation :current-tab="'create'" />

      <div class="tabs__content" v-if="!loading">
        <tab-content-create
          :travel="plan"
          :existing-plan="$route.params.id !== undefined"
          :plan-modify="updatePreview"
        />
      </div>
    </section>
  </div>
</template>

<script>
import TravelPreview from "../components/travelPlans/TravelPreview";
import TabsNavigation from "../components/travelPlans/TabsNavigation";
import TabContentCreate from "../components/travelPlans/TabContentCreate";
import _ from "lodash";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "TravelCreate",
  components: {
    TravelPreview,
    TabsNavigation,
    TabContentCreate,
  },
  data() {
    return {
      plan: null,
      loading: null,
    };
  },

  async beforeMount() {
    if (!this.$route.params.id) {
      this.plan = this.travel;
      if (this.plans.length === 0) {
        await this.$router.push({ name: "TravelCreate" });
      }
    } else {
      try {
        this.loading = this.$loading.show({
          loader: "spinner",
          color: "white",
        });
        this.plan = await this.fetchPlanWithId(this.$route.params.id);
        this.plan.timeConstraint = _.filter(
          this.plan.timeConstraint,
          (c) => c !== null
        );
      } catch (e) {
        await this.$router.push({ name: "TravelCreate" });
      } finally {
        this.loading.hide();
        delete this.loading;
      }
    }
  },

  computed: {
    ...mapGetters({
      plans: "plans/allPlans",
      getPlanById: "plans/getPlanById",
    }),
  },

  methods: {
    ...mapActions({
      fetchPlanWithId: "plans/fetchPlanWithId",
    }),

    updatePreview(val) {
      this.plan = val;
    },
  },
};
</script>

<style lang="scss"></style>
