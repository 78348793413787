<template>
  <v-text-field
    :placeholder="placeholder"
    :tabindex="$input.tabindex"
    :type="type"
    v-model="text"
    :hint="errorMessage"
    :persistent-hint="hasError"
    :label="label"
    :autocomplete="!autocomplete ? 'input-' + id : undefined"
    :value="value"
  />
</template>

<script>
import $input from "./input-base.mixin";
import randomId from "random-entities/id";

export default {
  name: "input-text",
  mixins: [$input],
  props: {
    value: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    autocomplete: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      id: randomId(),
      text: this.value
    };
  },

  watch: {
    text(to) {
      this.$emit("input", to);
    }
  },

  beforeMount() {
    if (!this.value) {
      this.text = "";
    }
    else {
      this.text = this.value;
    }
  }
};
</script>
