import Vue from "vue";
import Router from "vue-router";

import { store } from "@/store";

// Import Pages
import Login from "../pages/Login";
import TravelPlan from "../pages/TravelPlan";
import TravelCreate from "../pages/TravelCreate";
import TravelFuture from "../pages/TravelFuture";
import Settings from "../pages/Settings";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import Profile from "../pages/Profile";
import AMESsurvey from "../pages/AMESsurvey";
import ResetPassword from "../pages/ResetPassword";

Vue.use(Router);

export const router = new Router({
  mode: process.env.NODE_ENV !== "production" ? "hash" : "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      meta: {
        title: "INS - Connexion",
        publicPage: true
      },
      redirect: "/login"
    },
    {
      name: "TermsAndConditions",
      path: "/terms-and-conditions",
      meta: {
        title: "INS - Terms and Conditions",
        publicPage: true,
        layout: "login"
      },
      component: () => import("../pages/policies/TermsAndConditionsPolicy.vue")
    },
    {
      name: "PrivacyPolicy",
      path: "/privacy-policy",
      meta: {
        title: "INS - Privacy Policy",
        publicPage: true,
        layout: "login"
      },
      component: () => import("../pages/policies/PrivacyPolicy.vue")
    },
    {
      name: "Login",
      path: "/login",
      meta: {
        title: "INS - Login",
        publicPage: true,
        layout: "login"
      },
      component: Login
    },

    {
      name: "Register",
      path: "/register",
      meta: {
        title: "INS - Sign Up",
        publicPage: true,
        layout: "login"
      },
      component: SignUp
    },

    {
      name: "ForgotPassword",
      path: "/forgot-password",
      meta: {
        title: "INS - Mot de passe oublié",
        publicPage: true,
        layout: "login"
      },
      component: ForgotPassword
    },

    {
      name: "ResetPassword",
      path: "/reset-password",
      meta: {
        title: "INS - Modification du mot de passe oublié",
        publicPage: true,
        layout: "login"
      },
      component: ResetPassword
    },

    {
      name: "DeleteAccount",
      path: "/delete-account",
      meta: {
        title: "INS - Supression du compte",
        publicPage: true,
        layout: "login"
      },
      component: () => import("../pages/DeleteAccount.vue")
    },

    {
      name: "Profile",
      path: "/profile",
      meta: {
        title: "INS - My profile",
        publicPage: false
      },
      component: Profile
    },

    {
      path: "/travel",
      redirect: "/travel/plan"
    },

    {
      name: "TravelPlan",
      path: "/travel/plan",
      component: TravelPlan,
      meta: {
        publicPage: false
      },
      props: true
    },

    {
      name: "TravelCreate",
      path: "/travel/create",
      component: TravelCreate,
      meta: {
        publicPage: false
      },
      props: true
    },

    {
      name: "TravelEdit",
      path: "/travel/edit/:id",
      component: TravelCreate,
      meta: {
        publicPage: false
      },
      props: true
    },

    {
      name: "TravelFuture",
      path: "/travel/future",
      component: TravelFuture,
      meta: {
        publicPage: false
      },
      props: true
    },

    {
      name: "Settings",
      path: "/settings",
      component: Settings,
      meta: {
        publicPage: false
      },
      props: true
    },
    {
      name: "AMES",
      path: "/ames",
      component: AMESsurvey,
      meta: {
        publicPage: false
      },
      props: true
    },

    { path: "*", redirect: "/" }
  ],

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {

  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = !to.meta.publicPage;
  const loggedIn = store.getters["user/isConnected"];
  const user = store.getters["user/currentUser"];

  if (authRequired && !loggedIn) {
    return next({ name: "Login" });
  }

  // Force user to complete it profile before creating a new plan
  if (user) {
    if (to.name === "Login") {
      return next({ name: "TravelPlan" });
    }
    if (((!user.wakeUpTime || !user.bedTime) && to.name !== "Profile")) {
      return next({ name: "Profile" });
    } else if (to.name === "Profile") {
      return next();
    }

    if ((!user.ames_answers || user.ames_answers.total === 0) && to.name !== "AMES") {
      return next({ name: "AMES" });
    } else if (to.name === "AMES") {
      return next();
    }

    if (!authRequired && loggedIn && ['PrivacyPolicy', 'TermsAndConditions','DeleteAccount'].includes(to.name) === false) {
      return next({ name: "TravelPlan" });
    }
  }

  next();
});

