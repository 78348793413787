<template>
  <div>
    <form @submit.prevent="noop" v-on:keyup.enter="validateBeforeSubmit">
      <input-password :errors="$v.password" v-model.trim="password" />
      <input-password
        :errors="$v.passwordConfirmation"
        v-model.trim="passwordConfirmation"
        :label="$t('inputs.confirmPassword.label')"
      />
      <!-- Actions -->
      <div class="buttons-container">
        <router-link :to="{ name: 'Login' }" class="link-simple">
          {{ $t("buttons.backToLogin") }}
        </router-link>
        <button
          class="button__primary -filled"
          type="button"
          @click="validateBeforeSubmit"
        >
          {{ $t("buttons.send") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";

import ApiRequestFailedException from "../exceptions/ApiRequestFailedException";
import InputPassword from "../components/common/inputs/InputPassword";
import { minLength, required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "reset-password",

  components: {
    InputPassword,
  },

  data() {
    return {
      password: null,
      passwordConfirmation: null,
      backToHome: false,
    };
  },

  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    passwordConfirmation: {
      required,
      sameAs: sameAs("password"),
    },
  },

  async mounted() {
    if (!this.$route.query.token) {
      this.backToHome = true;
      await this.$swal({
        text: this.$t("no-code.message"),
        icon: "error",
        confirmButtonText: this.$t("buttons.close"),
      });
      await this.$router.push({ name: "Login" });
    }
  },

  methods: {
    ...mapActions("user", ["resetPassword"]),

    noop() {
      // Empty
    },

    /**
     * Validate
     */
    async validateBeforeSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.error("INVALID FORM", this.$v);
        return;
      }
      return await this.sendResetPasswordRequest();
    },

    /**
     * Send the reset password.
     * @returns {Promise<void>}
     */
    async sendResetPasswordRequest() {
      this.loading = this.$loading.show({
        loader: "spinner",
        color: "white",
      });

      try {
        // Send the email
        await this.resetPassword({
          token: this.$route.query.token,
          password: this.password,
        });

        this.loading.hide();

        // Show success message
        this.backToHome = true;
        await this.$swal({
          text: this.$t("success.message"),
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });

        // Route home
        await this.$router.push({ name: "Login" });
      } catch (err) {
        this.loading.hide();
        let message;

        // Api error
        if (err instanceof ApiRequestFailedException) {
          switch (err.errorCode) {
            case "Auth.form.error.code.provide":
              message = this.$t("errors.invalidCode");
              break;
          }
        }

        // Unknown error
        if (!message) {
          message = this.$t("errors.unknown");
        }

        // Show error
        await this.$swal({
          text: message,
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });
      }
    },
  },
  i18n: {
    messages: {
      fr: {
        buttons: {
          backToLogin: "Retour à la connexion",
        },
        "no-code": {
          title: "Erreur",
          message: "Votre demande de modification du mot de passe est incomplète. Veuillez ouvrir une nouvelle demande.",
        },
        success: {
          title: "Succès",
          message: "Votre mot de passe a été modifié. Veuillez vous connecter avec votre nouveau mot de passe.",
        },
        error: {
          title: "Erreur",
        },
        errors: {
          invalidCode: "Le code de réinitialisation de votre mot de passe a expiré. Veuillez faire une nouvelle demande d'oubli de votre mot de passe.",
          unknown: "Une erreur inconnue est survenue. Veuillez réessayer plus tard.",
        },
      },
      en: {
        buttons: {
          backToLogin: "Back to login",
        },
        "no-code": {
          title: "Error",
          message: "Your request to change the password is incomplete. Please restart the forgot password flow again.",
        },
        success: {
          title: "Success",
          message: "Your password has been changed. Please log in with your new password.",
        },
        error: {
          title: "Error",
        },
        errors: {
          invalidCode: "Your password reset code has expired. Please make a new forgot password request.",
          unknown: "An unknown error has occurred. Please try again later.",
        },
      },
    },
  },
};
</script>

<style scoped lang="scss">
p.instructions {
  margin-top: 2rem;
  font-size: 0.9rem;
}
</style>
