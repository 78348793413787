<template>
  <div id="deleteAccount">
    <section class="container-padding">

      <h1>{{ $t("title") }}</h1>
      <p v-html="$t('description')"/>

    </section>

    <form @submit.prevent="noop">

      <template v-if="!user">
        <input-text v-model="first_name" :label="`${$t('form.first_name')}*`"/>
        <input-text v-model="last_name" :label="`${$t('form.last_name')}*`"/>
        <input-emails v-model="email" :label="`${$t('form.email')}*`"/>
      </template>
      <input-text v-model="comment" :label="`${$t('form.comment')}`"/>

      <div style="display: flex">
        <vue-recaptcha class="m-auto" :sitekey="recaptchaKey" :language="$i18n.locale" :loadRecaptchaScript="true"/>
      </div>

      <div class="buttons-container">
        <button
            class="button__primary -cancel"
            @click="() => this.$router.push({ name: 'Login' })"
        >
          {{ $t("form.cancel") }}
        </button>
        <button
            class="button__primary -filled button-confirm"
            @click="validateBeforeSubmit"
        >
          {{ $t("form.submit") }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';

import InputText from "@/components/common/inputs/InputText.vue";
import InputEmails from "@/components/common/inputs/InputEmail.vue";
import config from "../config";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "DeleteAccount",
  components: { InputEmails, InputText, VueRecaptcha },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      comment: "",
    };
  },
  mounted() {
    if (this.user) {
      this.first_name = this.user.firstName;
      this.last_name = this.user.lastName;
      this.email = this.user.email;
    }
  },
  computed: {
    ...mapGetters("user", {
      user: "currentUser",
    }),
    recaptchaKey() {
      return config.recaptchaKey;
    }
  },
  methods: {
    ...mapActions("user", {
      deleteUser: "delete",
      logout: "logout"
    }),

    noop() {
      // Empty
    },

    async validateBeforeSubmit() {
      // Validate content
      if (
          !this.first_name ||
          !this.last_name ||
          !this.email
      ) {
        await this.$swal({
          text: this.$t("missing.message"),
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close"),
        });
        return;
      }

      // Confirm we can get the Recaptcha
      const recaptcha = window.grecaptcha.getResponse();
      if (recaptcha === '') {
        await this.$swal({
          text: this.$t('missing.recaptcha'),
          icon: 'error',
          showConfirmButton: true,
          confirmButtonText: this.$t('buttons.close')
        })
      }

      this.loading = this.$loading.show({
        loader: "spinner",
        color: "white",
      });

      try {
        // Send form answers to API
        await this.deleteUser({
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          comment: this.comment,
          recaptcha
        });

        this.loading.hide();

      } catch (error) {

        this.loading.hide();

        console.error('Failed to delete account', error.response.data);

        if (error.response.status === 422) {
          if (error.response.data.message === "request-already-existing") {
            // continue
          } else if (error.response.data.message === "user-dont-exists") {
            await this.$swal({
              text: this.$t("error.user-dont-exists"),
              icon: "error",
              showConfirmButton: true,
              confirmButtonText: this.$t("buttons.close"),
            });
            return false;
          }
        } else {
          await this.$swal({
            text: this.$t("error.message"),
            icon: "error",
            showConfirmButton: true,
            confirmButtonText: this.$t("buttons.close"),
          });
          return false;
        }

      }

      await this.$swal({
        text: this.$t("success.message"),
        icon: "success",
        showConfirmButton: true,
        confirmButtonText: this.$t("buttons.close"),
      });

      // Logout the user
      await this.logout();

      // Back to the login page
      await this.$router.push({
        name: "Login",
      });

      return false;
    }
  },
  i18n: {
    messages: {
      fr: {
        title: "Demande de suppression du compte utilisateur",
        description: "Si vous souhaitez supprimer votre compte, veuillez remplir le formulaire ci-dessous.<br /><br />" +
            "Veuillez noter que la suppression de votre compte est irréversible et entraînera la perte de toutes les données associées à celui-ci. " +
            "Assurez-vous de sauvegarder toutes les informations importantes avant de procéder à la suppression.<br /><br />" +
            "Une fois que vous aurez soumis ce formulaire, nous traiterons la suppression de votre compte dans les plus brefs délais. Vous serez déconnecté automatiquement et il ne vous sera plus possible de vous connecter.<br /><br />" +
            "Nous regrettons de voir partir, et nous espérons vous revoir à l'avenir.",
        warning: "Attention, cette action est irréversible.",
        form: {
          first_name: "Prénom",
          last_name: "Nom",
          email: "Courriel",
          comment: "Commentaire",
          submit: "Envoyer la demande",
          cancel: "Annuler"
        },
        buttons: {
          close: "Fermer"
        },
        missing: {
          title: "Erreur",
          message: "Afin de procéder à l'envoi de votre demande, veuillez compléter tous les champs obligatoires.",
        },
        success: {
          title: "Succès",
          message: "Votre demande a été dûment transmise et votre compte est à présent bloqué jusqu'à sa suppression par un membre de notre équipe.",
        },
        error: {
          title: "Erreur",
          message: "Une erreur s'est produite. Veuillez vérifier que vous avez rempli intégralement le formulaire. Si ce n'est pas le cas, veuillez réessayer ultérieurement.",
          "user-dont-exists": "L'adresse courriel fournie ne correspond à aucun compte utilisateur. Veuillez vérifier l'exactitude de l'adresse courriel et réessayer ultérieurement."
        },
      },
      en: {
        title: "User Account Deletion Request",
        description: "If you wish to delete your account, please fill out the form below.<br /><br />" +
            "Please note that deleting your account is irreversible and will result in the loss of all associated data. " +
            "Make sure to backup any important information before proceeding with the deletion.<br /><br />" +
            "Once you submit this form, we will process the deletion of your account as soon as possible. You will be automatically logged out and unable to log in again.<br /><br />" +
            "We're sorry to see you go, and we hope to see you again in the future.",
        warning: "Warning, this action is irreversible.",
        form: {
          first_name: "First Name",
          last_name: "Last Name",
          email: "Email",
          comment: "Comment",
          submit: "Submit Request",
          cancel: "Cancel"
        },
        buttons: {
          close: "Close"
        },
        missing: {
          title: "Error",
          message: "To submit your request, please complete all required fields.",
        },
        success: {
          title: "Success",
          message: "Your request has been duly submitted, and your account is now blocked until it is deleted by a member of our team."
        },
        error: {
          title: "Error",
          message: "An error occurred. Please check if you have filled out the form completely. If not, please try again later.",
          "user-dont-exists": "The provided email address does not correspond to any user account. Please verify the correctness of the email address and try again later."
        },
      },
    }
  }
}
</script>

<style lang="scss">
h1 {
  text-align: center;
  padding-bottom: 20px;
}
</style>
