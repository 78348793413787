<template>
  <v-app>
    <component :class="{layout: this.layout !== 'onboarding-layout'}" :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import "./stylesheets/main.scss";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import _ from "lodash";

import elementMixin from "@/mixins/elementMixin";

const default_layout = "default";

export default {
  data() {
    return {
      notifications: false
    };
  },
  components: {},
  mixins: [ elementMixin ],
  computed: {

    ...mapGetters("user", {
      "user": "currentUser"
    }),

    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  },

  methods: {

    ...mapActions({
      fetchAirports: "airports/fetchAirports",
      fetchPlans: "plans/fetchPlans",
      logout: "user/logout"
    }),

    async pushPermissions() {
      if (window.cordova && window.cordova.plugins?.notification) {
        window.cordova.plugins.notification.local.hasPermission((granted) => {
          this.notifications = granted;
          if (!granted) {
            window.cordova.plugins.notification.local.requestPermission(async (granted) => {
              if (granted) {
                this.notifications = granted;
              } else {
                // Show message that notification needs to be enabled
                await this.$swal({
                  text: this.$t("permissions.error"),
                  icon: "error",
                  showConfirmButton: true,
                  confirmButtonText: this.$t("buttons.close")
                });
              }
            });
          }
        });
      }
    },

    /*async redoAllNotifications(notifications) {
      if (this.notifications) {
        window.cordova.plugins.notification.local.cancelAll();

        for (let notif in notifications) {
          for (let day of notif.timeline) {
            for (let entry of day.entries) {
              window.cordova.plugins.notification.local.schedule({
                title: 'Perform Away',
                text: this.resolveName(entry, this.user),
                trigger: {at: new Date(entry.startUtcTime)}
              });
            }
          }
        }
      }
    }*/

  },

  watch: {
    // Update Axios headers
    "$store.state.user.token"(to) {
      if (_.isString(to)) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + to;
      } else {
        axios.defaults.headers.common["Authorization"] = null;
      }
    }
  },

  beforeCreate() {
    // Enable axios if required
    if (this.$store.getters["user/getToken"]) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters["user/getToken"];
    }
    // Online store state
    this.intervalId = setInterval(() => {
      if (this.$store.state.online !== navigator.onLine) {
        this.$store.commit("onlineStateChanged", navigator.onLine);
      }
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  beforeMount() {
    if (this.$store.getters["user/isConnected"]) {
      this.fetchAirports();
      try {
        this.fetchPlans();
      } catch (e) {
        if (e.message === "not-logged-in") {
          this.logout();
          window.location.href = "/";
          window.localStorage.removeItem("vuex");
        }
      }
      this.$root.$i18n.locale = this.$store.getters["user/currentUser"].language;
    }
  },

  mounted() {

    this.$vuetify.theme.dark = true;

    if (window.navigator && window.navigator.splashscreen) {
      navigator.splashscreen.hide();
    }

    if (this.$store.getters["user/isConnected"]) {
      // Refresh user
      this.$store.dispatch("user/refreshUser");
    }

    // Check for push permissions
    this.pushPermissions();

    if (window.cordova) {

      // Setup notification defaults
      window.cordova.plugins.notification.local.setDefaults({
        vibrate: false
      });

      window.cordova.plugins.notification.local.hasPermission(function (granted) {
        if (!granted) {
          window.cordova.plugins.notification.local.requestPermission();
        }
      });

    }

  }
};
</script>

<style lang="scss">

/*@import url('https://use.fontawesome.com/releases/v5.0.1/css/all.css');*/

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient($color-light-blue, $color-dark-blue) fixed;
  /*background-size: 100vw 100vh;*/
  /*background-position: center;*/
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  .gradient-background {
    height: 100vh;
    width: 100vw;
    background: linear-gradient($color-light-blue, $color-dark-blue);
    position: fixed;
    top: 0;
    left: 0;
    z-index: -5;
  }

  .layout {
    /*padding: 1rem;*/
    margin: 0 auto;
    min-height: calc(100vh - $header-size);
    /*max-width: 1140px;*/
    /* overflow: scroll; */
    max-height: 100%;
    /*max-width: 100%;*/
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  /* a subtle focus style for keyboard-input elements */
  .text-input:focus {
    outline: 1px solid #aaa;
  }

  /* no focus style for non-keyboard-inputs elements */
  button:focus,
  select:focus,
  input:focus {
    outline: none;
  }

  /* and for keyboard users, override everything with
     a Big Blue Border when focused on any element */
  body.user-is-tabbing *:focus {
    outline: 2px solid #7AACFE !important; /* for non-webkit browsers */
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}

.fixfixed .header,
.fixfixed .navigation {
  position: absolute;
}
</style>
