<template>
  <div>
    <form @submit.prevent="validateBeforeSubmit" action="">
      <input-text
          :errors="$v.firstName"
          v-model.trim="firstName"
          :label="$t('inputs.firstName.label')"
          :placeholder="$t('inputs.firstName.placeholder')"
      />
      <input-text
          :errors="$v.lastName"
          v-model.trim="lastName"
          :label="$t('inputs.lastName.label')"
          :placeholder="$t('inputs.lastName.placeholder')"
      />

      <input-email
          :errors="$v.email"
          v-model.trim="email"
      />

      <input-password
          :errors="$v.password"
          v-model.trim="password"
      />

      <input-password
          :errors="$v.confirmPassword"
          v-model.trim="confirmPassword"
          :label="$t('inputs.confirmPassword.label')"
      />

      <br/>
      <p v-if="$i18n.locale === 'fr'">
        En vous inscrivant, vous acceptez les <a @click="termsAndConditions = true">Conditions d'utilisation</a> et la
        <a @click="privacyPolicy = true">Politique de confidentialité</a>.
      </p>
      <p v-else>
        By signing up, you agree to the <a @click="termsAndConditions = true">Terms of Service</a> and the
        <a @click="privacyPolicy = true">Privacy Policy</a>.
      </p>

      <div class="buttons-container">
        <router-link :to="{ name: 'Login' }" class="link-simple">
          {{ $t("buttons.alreadyMember") }}
        </router-link>
        <button type="submit" class="button__primary">
          {{ $t("buttons.register") }}
        </button>
      </div>
    </form>

    <modal v-if="privacyPolicy === true" @close="privacyPolicy = false">
      <template #content>
        <PrivacyPolicy/>
      </template>
    </modal>

    <modal v-if="termsAndConditions === true" @close="termsAndConditions = false">
      <template #content>
        <TermsAndConditionsPolicy/>
      </template>
    </modal>

  </div>
</template>

<script>
import InputText from "@/components/common/inputs/InputText";
import InputEmail from "@/components/common/inputs/InputEmail";
import InputPassword from "@/components/common/inputs/InputPassword";
import PrivacyPolicy from "@/pages/policies/PrivacyPolicy.vue";
import TermsAndConditionsPolicy from "@/pages/policies/TermsAndConditionsPolicy.vue";

import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

import ApiRequestFailedException from "../exceptions/ApiRequestFailedException";
import Modal from "@/components/Modal.vue";

export default {
  name: "SignUp",
  components: {
    Modal,
    TermsAndConditionsPolicy,
    PrivacyPolicy,
    InputText,
    InputEmail,
    InputPassword
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      privacyPolicy: false,
      termsAndConditions: false
    };
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required,
      minLength: minLength(6)
    },
    confirmPassword: {
      required,
      sameAs: sameAs("password")
    },
    firstName: {
      required,
      minLength: minLength(2)
    },
    lastName: {
      required,
      minLength: minLength(2)
    },
    privacyPolicy: {
      required
    },
    termsAndConditions: {
      required,
    }
  },
  methods: {
    ...mapActions("user", [ "register" ]),

    async validateBeforeSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.error("INVALID FORM", this.$v);
        return;
      }
      return await this.registerUser();
    },

    /**
     * Register the new user.
     * @returns {Promise<void>}
     */
    async registerUser() {
      this.loading = this.$loading.show({
        loader: "spinner",
        color: "white"
      });

      try {
        await this.register({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password
        });

        this.loading.hide();

        // Show must confirm email
        await this.$swal({
          text: this.$t("success.message"),
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close")
        });

        await this.$router.push({ name: "Login" });
      } catch (error) {
        this.loading.hide();

        let message;

        // Check if API error
        if (error instanceof ApiRequestFailedException) {
          switch (error.errorCode) {
            case "Auth.form.error.password.provide":
              message = this.$t("errors.passwordRequired");
              break;
            case "Auth.form.error.email.provide":
              message = this.$t("errors.emailRequired");
              break;
            case "Auth.form.error.password.format": // Asked by Strapi
              message = this.$t("errors.passwordFormat");
              break;
            case "Auth.form.error.email.format":
              message = this.$t("errors.emailFormat");
              break;
            case "Auth.form.error.email.taken":
              message = this.$t("errors.emailTaken");
              break;
          }
        }

        // Otherwise, the error is unknown
        if (!message) {
          message = this.$t("error.unknown");
        }

        // Show error message
        await this.$swal({
          text: message,
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close")
        });
      }
    }
  },
  i18n: {
    messages: {
      "fr": {
        "error": "Erreur",
        "errors": {
          "passwordMatch": "Le mot de passe ne correspond pas.",
          "passwordRequired": "Vous devez fournir un mot de passe.",
          "emailRequired": "Vous devez fournir une adresse courriel.",
          "passwordFormat": "Votre mot de passe ne peut pas contenir plus de 3 fois le symbole $.",
          "emailFormat": "Vous devez fournir une adresse courriel valide.",
          "emailTaken": "Un compte existe déjà avec ce courriel. Veuillez essayer de vous connecter ou de choisir une autre adresse courriel.",
          "unknown": "Une erreur inconnue est survenue. Veuillez réessayer plus tard ou contacter le support technique."
        },
        "success": {
          "title": "Succès",
          "message": "Vous avez été inscrit avec succès. Vous allez recevoir un courriel dans les prochaines minutes afin de confirmer votre adresse courriel."
        },
        "inputs": {
          "privacyPolicy": "Je certifie avoir pris connaissance de la politique de confidentialité et je confirme mon acceptation des termes en vigueur.",
          "termsAndConditions": "Je certifie avoir pris connaissance des termes et conditions et je les accepte."
        }
      },
      "en": {
        "error": "Error",
        "errors": {
          "passwordMatch": "Password does not match.",
          "passwordRequired": "You must provide a password.",
          "emailRequired": "You must provide an email address.",
          "passwordFormat": "Your password cannot contain more than 3 times the symbol $.",
          "emailFormat": "You must provide a valid email address.",
          "emailTaken": "An account already exists with this email. Please try to login or choose another email address.",
          "unknown": "An unknown error has occurred. Please try again later or contact technical support."
        },
        "success": {
          "title": "Success",
          "message": "You have been successfully registered. You will receive an email in the next few minutes to confirm your email address."
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
