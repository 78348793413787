import Vue from "vue";
import axios from "axios";
import _ from "lodash";

export default {
  namespaced: true,
  state: () => {
    return {
      plans: {}
    };
  },
  mutations: {
    /**
     * Add a new plan or update an existing plan.
     * @param {object} state
     * @param {object} plan
     */
    upsertPlan(state, plan) {
      Vue.set(state.plans, plan.id, plan);
    },
    /**
     * Remove a plan from the state.
     * @param {object} state
     * @param {number} id
     */
    removePlan(state, id) {
      if (state.plans[id] !== undefined) {
        Vue.delete(state.plans, id);
      }
    },
    /**
     * Remove all plans.
     * @param {object} state
     * @param {string[]} idsToKeep
     */
    clearPlans(state, idsToKeep) {
      let plans = state.plans;
      for (const id in plans) {
        if (!idsToKeep.includes(id)) {
          delete plans[id];
        }
      }
      Vue.set(state, "plans", plans);
    }
  },
  getters: {
    /**
     * Return all the plans with sort.
     * @param state
     * @returns {object[]}
     */
    allPlans: state => {
      return _.orderBy(Object.values(state.plans), ["departureDate", "departureTime", "arrivalDate"], ["asc"]);
    },
    /**
     * Get specific plan with given ID.
     *
     * @returns function
     */
    getPlanById: (state) => (id) => {
      return state.plans[id];
    }
  },
  actions: {
    /**
     * Fetch all plans for the current user.
     * @param {function} commit
     * @returns {Promise<void>}
     */
    fetchPlans: async function({ commit }) {
      let response;
      try {
        response = await axios.get(`/plans`);
      }
      catch (error) {
        console.error("Failed to fetch the plans");
        throw new Error(error.response.data.message);
      }

      if (response.status === 403) {
        throw new Error("not-logged-in");
      }

      // Clear state
      commit("clearPlans", _.map(response.data, (d) => d._id));

      // Add plan to state
      for (const plan of response.data) {
        commit("upsertPlan", plan);
      }

      return response.data;
    },

    /**
     * Fetch the plan with ID.
     * @param commit
     * @param id
     * @returns {Promise<any>}
     */
    async fetchPlanWithId({ commit }, id) {
      let response;
      try {
        response = await axios.get(`/plans/` + id);
      }
      catch (error) {
        console.error("Failed to fetch the plan with ID: " + id);
        throw new Error(error.response.data.message);
      }

      // Add plan to state
      commit("upsertPlan", response.data);

      return response.data;
    },

    /**
     * Create or update the given plan. To update, the plan must have an ID.
     * @param {function} commit
     * @param {object} plan
     * @param {boolean} confirm - If required, set to true to force the backend to create/update the plan.
     * @returns {Promise<any>}
     */
    async upsertPlan({ commit }, {plan, confirm = false}) {
      let response;
      try {
        response = await axios.request({
          url: `/plans` + (plan._id !== undefined ? `/${plan._id}` : "") + (confirm === true ? '?confirmed=true' : ''),
          method: plan._id !== undefined ? "PUT" : "POST",
          data: plan
        });
      }
      catch (error) {
        console.error("Failed to create/update the plans");
        throw new Error(error.response.data.message);
      }

      // Save the plan into state
      if((response.data.conflicts && confirm) || !response.data.conflicts) {
        commit("upsertPlan", response.data.plan);
      }

      return response.data;
    },
    /**
     * Delete an existing plan with the given ID.
     * @param {function} commit
     * @param {number} id
     * @returns {Promise<boolean>}
     */
    async deletePlan({ commit }, id) {
      try {
        await axios.delete(`/plans/${id}`);
      }
      catch (error) {
        console.error("Failed to delete the plans");
        throw new Error(error.response.data.message);
      }

      // Removing the plan from the state
      commit("removePlan", id);

      return true;
    }
  }
};
