<template>
  <div class="profileImage">
    <div class="profileImage-container">
      <font-awesome-icon
        v-if="profilePicture === ''"
        :icon="['far', 'user']"
        class="icon"
      />
      <img v-else :src="profileImage" alt="Profile picture">
    </div>

    <p>{{ user.firstName }} {{ user.lastName }}</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProfileImage",
  data() {
    return {
      profilePicture: "/default-profile.png"
    };
  },
  computed: {
    ...mapGetters("user", {
      "user": "currentUser"
    }),
    profileImage() {
      if (!this.user.profilePicture) {
        return "/default-profile.png";
      }
      let split = this.user.profilePicture.url.split("/upload/");
      return split[0] + "/upload/" + "c_fill,g_face,h_300,q_100,w_300/" + split[1];
    }
  }
};
</script>

<style scoped lang="scss">
.profileImage {
  margin-bottom: 2.5rem;
  text-align: center;

  &-container {
    width: 5rem;
    height: 5rem;
    background-color: $color-medium-gray;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 1rem auto;

    .icon {
      font-size: 2rem;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  p {
    font-style: italic;
  }
}

</style>
