import config from "./config";

// Axios config
import axios from "axios";
axios.defaults.baseURL = config.apiUrl;

import Vue from "vue";
import App from "./App.vue";
import VueI18n from "vue-i18n";
import i18n from "./i18n";
import { router } from "./router";
import { store } from "./store";
import Loading from "vue-loading-overlay";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import VueSweetalert2 from "vue-sweetalert2";
import vuetify from "./plugins/vuetify";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// import VueLodash from 'vue-lodash';
import _ from "lodash";
import VueFuse from "vue-fuse";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import VShowSlide from "v-show-slide";
import Vuelidate from "vuelidate";

//Import Layouts
import Default from "./layouts/Default-layout.vue";
import Login from "./layouts/Login-layout.vue";
import "vue2-timepicker/dist/VueTimepicker.css";
import "vue-loading-overlay/dist/vue-loading.css";

// Fontawesome
import {
  faMapMarkerAlt,
  faEnvelope,
  faSocks,
  faPlaneDeparture,
  faPlaneArrival,
  faSun,
  faMoon,
  faChevronDown,
  faChevronUp,
  faAdjust,
  faRunning,
  faBed,
  faTint,
  faAtom,
  faUtensils,
  faPlusCircle,
  faPlus,
  faTimesCircle,
  faCapsules,
  faPills,
  faBolt,
  faUser,
  faExclamationCircle,
  faPencilAlt,
  faTrash,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

import {
  faBell,
  faLightbulb,
  faClock,
  faCalendarAlt
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

// Croppa Stylesheet
//import "vue-croppa/dist/vue-croppa.css";

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(_);
Vue.use(VueFuse);
Vue.use(Autocomplete);
Vue.use(VShowSlide);
Vue.use(Vuelidate);
Vue.use(Loading);
Vue.use(VueNumberInput);
Vue.use(VueSweetalert2);

//Use layouts
Vue.component("default-layout", Default);
Vue.component("login-layout", Login);

Vue.component("font-awesome-icon", FontAwesomeIcon);
library.add(
  faEnvelope,
  faLightbulb,
  faMapMarkerAlt,
  faSocks,
  faBell,
  faPills,
  faBolt,
  faUser,
  faPlaneDeparture,
  faPlaneArrival,
  faSun,
  faMoon,
  faChevronDown,
  faChevronUp,
  faAdjust,
  faRunning,
  faBed,
  faTint,
  faAtom,
  faUtensils,
  faPlusCircle,
  faPlus,
  faClock,
  faTimesCircle,
  faCalendarAlt,
  faCapsules,
  faExclamationCircle,
  faPencilAlt,
  faTrash,
  faSocks,
  faBolt,
  faArrowLeft
);

/*let handleOutsideClick = {};
Vue.directive("click-outside", {
  priority: 700,
  bind(el, binding) {
    // Define the function to be called on click, filter the excludes and call the handler
    handleOutsideClick[el.id] = (e) => {
      e.stopPropagation();
      // extract the handler and exclude from the binding value
      const { handler, exclude } = binding.value;
      // set variable to keep track of if the clicked element is in the exclude list
      let clickedOnExcludedEl = false;
      // if the target element has no classes, it won't be in the exclude list skip the check
      if (e.target._prevClass !== undefined && exclude) {
        // for each exclude name check if it matches any of the target element's classes
        for (const className of exclude) {
          clickedOnExcludedEl = e.target._prevClass.includes(className);
          if (clickedOnExcludedEl) {
            break; // once we have found one match, stop looking
          }
        }
      }
      // don't call the handler if our directive element contains the target element
      // or if the element was in the exclude list
      if (!(el.contains(e.target) || clickedOnExcludedEl)) {
        handler();
      }
    };
    // Register our outsideClick handler on the click/touchstart listeners
    //document.addEventListener("click", handleOutsideClick[el.id]);
    //document.addEventListener("touchstart", handleOutsideClick[el.id]);
    /*document.onkeydown = (e) => {
      //this is an option but may not work right with multiple handlers
      if (e.keyCode === 27) {
        // TODO: there are minor issues when escape is clicked right after open keeping the old target
        handleOutsideClick[el.id](e);
      }
    };
  },
  unbind(el) {
    // If the element that has v-outside-click is removed, unbind it from listeners
    //document.removeEventListener("click", handleOutsideClick[el.id]);
    //document.removeEventListener("touchstart", handleOutsideClick[el.id]);
    //document.onkeydown = null; //Note that this may not work with multiple listeners
  }
});*/

Object.defineProperty(Vue.prototype, "_", { value: _ });

// Setup Sentry
if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    debug: process.env.VUE_APP_SENTRY_DEBUG,
    environment: process.env.VUE_APP_SENTRY_ENV,
    attachStacktrace: process.env.VUE_APP_SENTRY_STACKTRACE,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "performaway.insquebec.org", /^\//]
      })
    ],

    tracesSampleRate: 0.05
  });
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");

// Mobile configuration
import "./mobile";

// Event listeners
window.addEventListener('keyboardDidShow', (event) => {
  document.body.style['margin-bottom'] = (event.keyboardHeight + 40) + 'px';
});

window.addEventListener('keyboardWillHide', () => {
  document.body.style['margin-bottom'] = null;
});

const fadeThreshold = 15; // Threshold distance from the top to start fading

window.addEventListener("scroll", function () {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  try {
    let titleDiv = document.getElementsByClassName("pageTitle")[0];
    if (scrollTop > fadeThreshold) {
      titleDiv.classList.add("fade-out");
      titleDiv.classList.remove("fade-in");
    } else {
      titleDiv.classList.remove("fade-out");
      titleDiv.classList.add("fade-in");
    }
  } catch (e) {
    // Do nothing
  }
});