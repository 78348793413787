export default class ApiRequestFailedException extends Error {

  /**
   * Build api request failed.
   * @param {int} statusCode - HTTP Status code
   * @param {string} errorCode - Error code extracted from the body
   * @param {string} errorMessage - Error message extracted from the body
   * @param {any} body - Error response body
   */
  constructor(statusCode, errorCode, errorMessage, body) {
    super(errorMessage);
    this._errorCode = errorCode;
    this._statusCode = statusCode;
    this._body = body;
  }

  /**
   * The HTTP status code returned.
   * @returns {Number}
   */
  get statusCode() {
    return this._statusCode;
  }

  get body() {
    return this._body;
  }

  get errorCode() {
    return this._errorCode;
  }

  get errorMessage() {
    return this.message;
  }

}
