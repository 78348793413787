<template>
  <div :class="{ subelement: true, 'timeline-conflict': isConflict }">
    <span class="bullet"></span>
    <div>
      <h5 class="time-details">{{ getEntryTime }}</h5>
      <div class="subelement-info">
        <span class="icon">
          <font-awesome-icon
            class=""
            :icon="getActivityIcon"
            v-if="getActivityIcon !== ''"
          />
        </span>
        <p>
          <template>{{ name }}</template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import formatTravelData from "@/mixins/formatTravelData";
import elementMixin from "@/mixins/elementMixin";

export default {
  name: "TimelineSubElement",
  mixins: [formatTravelData, elementMixin],
  props: {
    elementDetail: {
      type: Object,
      default() {
        return {
          time: "21:00 - 23:00",
          icon: "adjust",
          type: "Dark ideal before bed",
        };
      },
    },
    date: {
      type: String,
    },
  },

  computed: {
    ...mapGetters("user", {
      user: "currentUser",
    }),

    isConflict() {
      return this.elementDetail.conflict;
    },

    getEntryTime() {
      const startTime = this.formatLocalTime(
        this.date,
        this.elementDetail.start,
        this.elementDetail.timezone
      );
      const endTime = this.formatLocalTime(
        this.date,
        this.elementDetail.end,
        this.elementDetail.timezone
      );

      return startTime === endTime ? startTime : startTime + " - " + endTime;
    },

    name() {
      return this.resolveName(this.elementDetail, this.user);
    },

    getActivityIcon() {
      if (this.isConflict) {
        return ["fas", "exclamation-circle"];
      }
      if (this.elementDetail.symbol === "C") {
        return ["fas", "user"];
      }
      switch (this.elementDetail.action) {
        case "MELATONIN":
          return ["fas", "capsules"];
        case "SLEEP_IDEAL":
        case "SLEEP_IDEAL_OR_EARLIER":
        case "SLEEP_IDEAL_OR_LATER":
          return ["fas", "bed"];
        case "TAKE_OFF":
          return ["fas", "plane-departure"];
        case "ARRIVAL":
          return ["fas", "plane-arrival"];
        case "LIGHT_IDEAL":
        case "LIGHT_IDEAL_AFTER_WAKING":
        case "LIGHT_IDEAL_BEFORE_BED":
          return ["fas", "sun"];
        case "DARK_IDEAL":
        case "DARK_IDEAL_AFTER_WAKING":
        case "DARK_IDEAL_BEFORE_BED":
          return ["fas", "adjust"];
        case "PUT_ON_COMPRESSION_SOCKS":
        case "REMOVE_COMPRESSION_SOCKS":
          return ["fas", "socks"];
        case "PUT_ON_ELECTRO":
          return ["fas", "bolt"];
        default:
          return "";
      }
    },
  }
};
</script>

<style scoped lang="scss">
.timeline-conflict {
  .time-details,
  p,
  svg,
  h5 {
    color: red !important;
    fill: red;
  }
}

.subelement {
  position: relative;
  margin-bottom: 1rem;

  .bullet {
    position: absolute;
    top: 0.3rem;
    /*<!--margin-left: -2rem;-->*/
    left: -2rem;
    transform: translateX(-50%);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $color-primary;
    border: 1px solid $color-white;
  }

  .time-details {
    margin-bottom: 0.125rem;
    color: $color-extra-light-aqua;
    font-weight: 500;
  }

  &-info {
    display: flex;
    padding-left: 1.125rem;

    p {
      line-height: 1.3;
    }

    .icon {
      width: 1.75rem;
      flex: none;

      svg {
        max-width: 1.125rem;
      }
    }
  }
}
</style>
