<template>
  <div>
    <section v-if="user.bedTime === null || user.wakeUpTime === null">
      <alert-banner>
        {{ $t("incompleteProfile.text") }}
      </alert-banner>
    </section>

    <section id="picture-section">
      <img :src="profileImage" class="profilePicture" />
      <input
        type="file"
        v-on:change="updateProfilePicture"
        ref="profilePicture"
        style="display: none"
      />
      <a @click="generateImage" class="link-simple colorLightBlue underline">
        {{ $t("imageUpload.editPicture") }}
      </a>
    </section>
    <!--        <img :src="imgUrl" class="output">-->

    <section class="profile-container mt-1 flex-center">
      <form @submit.prevent="validateBeforeSubmit" class="mt-1">
        <!-- Main profile info -->
        <section class="mb-1">
          <input-text
            :label="$t('inputs.firstName.label')"
            :name="$t('inputs.firstName.label')"
            :placeholder="$t('inputs.firstName.placeholder')"
            :required="true"
            :rules="'required'"
            :type="'text'"
            v-model="user.firstName"
          />
          <input-text
            :label="$t('inputs.lastName.label')"
            :name="$t('inputs.lastName.label')"
            :placeholder="$t('inputs.lastName.placeholder')"
            :required="true"
            :rules="'required'"
            :type="'text'"
            v-model="user.lastName"
          />
          <input-text
            :label="$t('inputs.city.label')"
            :name="$t('inputs.city.label')"
            :placeholder="$t('inputs.city.placeholder')"
            :required="true"
            :rules="'required'"
            :type="'text'"
            v-model="user.city"
          />
          <input-email v-model="user.email" />
        </section>

        <!-- Main hours preferences-->
        <section class="time-selectors">
          <input-time
            :value="user.bedTime && user.bedTime !== null ? user.bedTime.substr(0, 5) : '22:00'"
            :icon-name="'bed'"
            :label="$t('preferences.wakeUpTime')"
            @input="updateBedTime"
            style="width: 100%"
          />
        </section>
        <section class="time-selectors" style="padding-top: 0">
          <input-time
            :value="user.wakeUpTime && user.wakeUpTime !== null ? user.wakeUpTime.substr(0, 5) : '7:00'"
            :icon-name="'sun'"
            @input="updateWakeUpTime"
            style="width: 100%"
          />
        </section>

        <!-- Preferences -->
        <section class="options-preference">
          <p class="input__label">{{ $t("preferences.title") }}</p>

          <check-box-option
            :icon="'lightbulb'"
            :prefix="'far'"
            :title="$t('preferences.blueLight')"
            v-model="user.items.blueLight"
          />

          <check-box-option
            :icon="'socks'"
            :prefix="'fas'"
            :title="$t('preferences.compression')"
            v-model="user.items.compressionSocks"
          />

          <check-box-option
            :icon="'bolt'"
            :prefix="'fas'"
            :title="$t('preferences.electrostimulation')"
            v-model="user.items.electrostimulation"
          />

          <check-box-option
            :icon="'pills'"
            :prefix="'fas'"
            :title="$t('preferences.melatonin')"
            v-model="user.items.melatonin"
          />
        </section>

        <section class="flex-center" style="margin-top: 50px;">
          <button
            class="button__primary -filled"
            type="submit"
            style="cursor: pointer; width: 100%; margin-bottom: 20px"
          >
            {{ $t("buttons.save") }}
          </button>

          <div
            v-if="user.ames_answers && user.ames_answers.total > 0"
            style="margin-top: 0px; margin-bottom: 20px; text-align: center"
          >
            <h3>{{ $t("ames.title") }}</h3>
            <p>{{ amesResult }}</p>
          </div>

          <router-link
            :to="{ name: 'AMES' }"
            class="button__primary -filled text-center"
            style="cursor: pointer; width: 100%"
          >
            <template v-if="user.ames_answers && user.ames_answers.total === 0">
              {{ $t("surveyLink") }}
            </template>
            <template v-else>
              {{ $t("formAmesLinkAgain") }}
            </template>
          </router-link>
        </section>
      </form>
    </section>
  </div>
</template>

<script>
// import 'vue-croppa/dist/vue-croppa.css';

import InputText from "../components/common/inputs/InputText";
import InputEmail from "../components/common/inputs/InputEmail";
import InputTime from "../components/common/inputs/InputTime";
import AlertBanner from "../components/AlertBanner";
import CheckBoxOption from "../components/common/CheckBoxOption";
import { mapActions } from "vuex";
import { email, minLength, required } from "vuelidate/lib/validators";
import _ from "lodash";

export default {
  name: "Profile",

  // https://zhanziyang.github.io/vue-croppa/#/
  data() {
    return {
      incompleteProfile: false,
      profilePicture: null,
      user: {
        firstName: null,
        lastName: null,
        city: null,
        email: null,
        items: {
          blueLight: false,
          compressionSocks: false,
          electrostimulation: false,
          melatonin: false
        },
        bedTime: null,
        wakeUpTime: null
      }
    };
  },
  components: {
    AlertBanner,
    InputEmail,
    InputText,
    InputTime,
    CheckBoxOption
  },
  beforeMount() {
    this.user = {
      ...this.user,
      ...this.$store.getters["user/currentUser"]
    };
    if (this.user.items === null) {
      this.user.items = {
        blueLight: false,
        compressionSocks: false,
        electrostimulation: false,
        melatonin: false
      };
    }
    this.incompleteProfile = _.clone(
      this.user.bedTime === null || this.user.wakeUpTime === null
    );
  },

  validations: {
    user: {
      email: {
        required,
        email
      },
      firstName: {
        required,
        minLength: minLength(2)
      },
      lastName: {
        required,
        minLength: minLength(2)
      },
      city: {
        minLength: minLength(3)
      }
    }
  },
  computed: {
    amesResult() {
      if (this.user.ames_answers) {
        if (this.user.ames_answers.total <= 12) {
          return this.$t("ames.result.extreme_evening_type");
        }
        else if (
          this.user.ames_answers.total > 12 &&
          this.user.ames_answers.total <= 17
        ) {
          return this.$t("ames.result.moderate_evening_type");
        }
        else if (
          this.user.ames_answers.total > 18 &&
          this.user.ames_answers.total <= 23
        ) {
          return this.$t("ames.result.mid_range");
        }
        else if (
          this.user.ames_answers.total > 24 &&
          this.user.ames_answers.total <= 28
        ) {
          return this.$t("ames.result.moderate_morning_type");
        }
        else {
          return this.$t("ames.result.extreme_morning_type");
        }
      }
      return null;
    },
    profileImage() {
      if (!this.$store.getters["user/currentUser"].profilePicture) {
        return "/default-profile.png";
      }
      let split =
        this.$store.getters["user/currentUser"].profilePicture.url.split(
          "/upload/"
        );
      return (
        split[0] + "/upload/" + "c_fill,g_face,h_300,q_100,w_300/" + split[1]
      );
    }
  },
  methods: {
    ...mapActions("user", ["update"]),

    updateBedTime(newTime) {
      this.$set(this.user, "bedTime", newTime);
    },

    updateWakeUpTime(newTime) {
      this.$set(this.user, "wakeUpTime", newTime);
    },

    async validateBeforeSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.error("INVALID FORM", this.$v);
        return;
      }

      this.loading = this.$loading.show({
        loader: "spinner",
        color: "white"
      });

      try {
        let data = {
          city: this.user.city,
          email: this.user.email,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          items: this.user.items,
          bedTime: this.user.bedTime,
          wakeUpTime: this.user.wakeUpTime
        };

        await this.update(data);

        this.loading.hide();

        // Now go to AMES if profile was incomplete at first
        if (this.incompleteProfile) {
          await this.$swal({
            text: this.$t("success.message"),
            icon: "success",
            showConfirmButton: true,
            confirmButtonText: this.$t("buttons.close")
          });
          // Route to AMES form
          await this.$router.push({ name: "AMES" });
        }
      }
      catch (e) {
        console.error("Failed to update profile", e);

        this.loading.hide();

        await this.$swal({
          text: this.$t("error.unknown"),
          icon: "error",
          showConfirmButton: true,
          confirmButtonText: this.$t("buttons.close")
        });
      }
    },

    async updateProfilePicture() {
      let formData = new FormData();
      let imageFile = this.$refs.profilePicture;
      formData.append("files.profilePicture", imageFile.files[0]);
      formData.append("data", JSON.stringify({ name: "profilePicture" }));
      let loading = this.$loading.show({
        loader: "spinner",
        color: "white"
      });
      await this.$store.dispatch("user/updateProfilePicture", formData);
      loading.hide();
    },

    generateImage() {
      this.$refs.profilePicture.click();
    },

    handleImageRemove() {
      this.myCroppa = null;
    }
  },
  i18n: {
    messages: {
      fr: {
        imageUpload: {
          placeholder: "Importer une image",
          editPicture: "Modifier la photo"
        },
        preferences: {
          title: "Préférences",
          blueLight: "Lumière bleue",
          compression: "Bas de compression",
          electrostimulation: "Électrostimulation",
          melatonin: "Mélatonine",
          location: "Localisation",
          notifications: "Notifications",
          wakeUpTime: "Heure habituelle de coucher et de lever"
        },
        surveyLink: "Remplir le formulaire AMES",
        formAmesLinkAgain: "Remplir à nouveau le formulaire AMES",
        incompleteProfile: {
          text: "Vous devez completer votre profil avant de pouvoir créer un plan."
        },
        success: {
          title: "Succès",
          message:
            "Nous avons maintenant besoin d'en savoir un peu plus sur vous pour s'adapter au maximum à vos besoins et vos habitudes.",
          button: "Continuer"
        },
        ames: {
          title:
            "Résultat du sondage AMES (Athlete's Morningness-Everningness Scale)",
          result: {
            extreme_evening_type: "Personne très couche-tard",
            moderate_evening_type: "Personne couche-tard",
            mid_range: "Personne dans la moyenne",
            moderate_morning_type: "Personne matinale",
            extreme_morning_type: "Personne très matinale"
          }
        }
      },
      en: {
        imageUpload: {
          placeholder: "Upload a photo",
          editPicture: "Update the photo"
        },
        preferences: {
          title: "Preferences",
          blueLight: "Blue light",
          compression: "Compression socks",
          electrostimulation: "Electrostimulation",
          melatonin: "Melatonin",
          location: "Localisation",
          notifications: "Notifications",
          wakeUpTime: "Usual sleep and wake up hour"
        },
        surveyLink: "Fill the AMES form",
        formAmesLinkAgain: "Fill the AMES form again",
        incompleteProfile: {
          text: "You must complete your profile before being able to create a plan."
        },
        success: {
          title: "Success",
          message:
            "We now need to know a little more about you to adapt as much as possible to your needs and habits.",
          button: "Continue"
        },
        ames: {
          title: "AMES form result",
          result: {
            extreme_evening_type: "Extreme evening type",
            moderate_evening_type: "Moderate evening type",
            mid_range: "Mid range",
            moderate_morning_type: "Moderate morning type",
            extreme_morning_type: "Extreme morning type"
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#picture-section {
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;

  .profilePicture {
    border-radius: 50%;
    align-self: center;
    margin-bottom: 0.5rem;
    max-width: 180px;

    &:hover,
    &:focus {
      opacity: 1;
    }

    .sk-fading-circle {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    & canvas {
      border-radius: 50% !important;
    }
  }
}

.profile-container {
  .username {
    width: 100%;
    text-align: center;
  }
}

.options-preference {
  padding-top: 1.5rem;
}
</style>
