import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

import user from "./modules/user";
import plans from "./modules/plan";
import airports from "./modules/airports";

const vuexLocal = new VuexPersistence({
  storage: localStorage,
  supportCircular: true,
  reducer: state => ({
    user: state.user,
    plans: state.plans,
    airports: state.airports
  })
});

export const store = new Vuex.Store({
  modules: {
    user,
    plans,
    airports
  },
  state: {
    online: false
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [vuexLocal.plugin],
  mutations: {
    RESTORE_MUTATION: vuexLocal.RESTORE_MUTATION,
    onlineStateChanged(state, isOnline) {
      Vue.set(state, "online", isOnline);
    }
  },
  getters: {
    isOnline: state => {
      return state.online;
    }
  }
});
