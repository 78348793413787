import _forEach from "lodash/forEach";

export default {
  props: {
    split: Boolean,
    required: Boolean,
    errors: { default: null },
    conditional: { type: Boolean, default: true }
  },

  computed: {
    _valid() {
      if (!this.errors) {
        return true;
      }
      return (Array.isArray(this.errors)) ? !this.errors.length : !Object.keys(this.errors).length;
    },
    _classes() {
      return {
        "-invalid": !this._valid,
        "-required": this.required,
        "-disabled": !this.conditional
      };
    },
    _tabindex() {
      return (!this.conditional) ? -1 : 0;
    },
    _tooltip() {
      if (this._valid) {
        return { message: "" };
      }
      return { message: this.errors[0] };
    },
    $input() {
      return {
        valid: this._valid,
        classes: this._classes,
        tooltip: this._tooltip,
        tabindex: this._tabindex
      };
    },
    hasError() {
      return this.errors && this.errors.$anyError;
    },
    errorMessage() {
      let error = null;
      if (this.errors) {
        _forEach(this.errors, (val, key) => {
          if (key.indexOf("$") === -1 && !val) {
            error = this.$t("validations." + key, this.errors.$params[key]);
          }
        });
      }
      return error;
    }
  },

  methods: {
    clearValidation() {
      if (!this._valid) {
        this.$emit("cleared");
      }
    },
    enableCondition() {
      if (!this.conditional) {
        this.$emit("enabled");
      }
    }
  }
};
