// Quick hack to force header to
setInterval(() => {
  if (window.cordova && window.cordova.platformId === "android" && window.StatusBar) {
    window.StatusBar.overlaysWebView(true);
  }
}, 100);

function detectInsets() {
  if (window.AndroidNotch) {
      const style = document.documentElement.style;

      // Apply insets as css variables

      window.AndroidNotch.getInsetTop(
          px => {
              style.setProperty("--notch-inset-top", px + "px");
          },
          err => console.error("Failed to get insets top:", err)
      );

      window.AndroidNotch.getInsetRight(
          px => {
              style.setProperty("--notch-inset-right", px + "px");
          },
          err => console.error("Failed to get insets right:", err)
      );

      window.AndroidNotch.getInsetBottom(
          px => {
              style.setProperty("--notch-inset-bottom", px + "px");
          },
          err => console.error("Failed to get insets bottom:", err)
      );

      window.AndroidNotch.getInsetLeft(
          px => {
              style.setProperty("--notch-inset-left", px + "px");
          },
          err => console.error("Failed to get insets left:", err)
      );
  }
}

document.addEventListener(
  "deviceready",
  () => {
      if (typeof window.cordova !== "undefined") {
          if (window.cordova.platformId === "android") {
              detectInsets();
          }
      }
  },
  false
);
